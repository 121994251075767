import { Dropdown, DropdownItem } from "@utdanningsdirektoratet/dropdown";
import { MenuPanel } from "@utdanningsdirektoratet/layout";
import { useHoering, useLocale, useUttalelse } from "../../../../hooks";
import { institusjonNavnLang } from "../../../../logic/initialState";
import { useContextState } from "../../../../StateProvider";
import { Målform } from "../../../../ApiClients";

export const Header = () => {
  const { data: hoering } = useHoering();
  const { data: uttalelse } = useUttalelse();
  const [, dispatch] = useContextState();
  const locale = useLocale();

  const muligeMalforms = hoering.målforms.split(", ") as Array<Målform>;

  const malformOptions = muligeMalforms.map((malform) => (
    <DropdownItem
      closeOnClick
      selected={malform === locale}
      onClick={() => dispatch({ type: "SET_LOCALE", payload: malform })}
    >
      {malform}
    </DropdownItem>
  ));

  return (
    <MenuPanel title={institusjonNavnLang} external>
      {malformOptions.length >= 2 && (
        <Dropdown disabled={uttalelse?.innsendt} lists={[malformOptions]}>
          {locale}
        </Dropdown>
      )}
    </MenuPanel>
  );
};
