import { getTextWithLocale } from "../../../../utils/localeUtils";
import { SignaturSeksjonViewModel } from "../../../../ApiClients";
import { useLocale } from "../../../../hooks";

const Signatur = ({
  signatur,
}: {
  signatur: SignaturSeksjonViewModel | null | undefined;
}) => {
  const locale = useLocale();
  if (!signatur) return null;
  const {
    footer,
    hilsen,
    signaturNavn1,
    signaturNavn2,
    signaturStilling1,
    signaturStilling2,
  } = signatur;

  const malformHilsen = getTextWithLocale(hilsen, locale);
  const malformSignaturStilling1 = getTextWithLocale(signaturStilling1, locale);
  const malformSignaturStilling2 = getTextWithLocale(signaturStilling2, locale);
  const malformFooter = getTextWithLocale(footer, locale);

  return (
    <div className="Page-section u--marginBottom2">
      <div className="Grid-12-12 u--marginBottom2">{malformHilsen}</div>
      <div className="Grid-6-12">
        <div>{signaturNavn1}</div>
        <div>{malformSignaturStilling1}</div>
      </div>
      <div className="Grid-6-12">
        <div>{signaturNavn2}</div>
        <div>{malformSignaturStilling2}</div>
      </div>
      <div className="Grid-12-12 u--marginTop2">{malformFooter}</div>
    </div>
  );
};

export default Signatur;
