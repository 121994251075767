import { Element } from "react-scroll";

import PositivNegativSvar from "./components/PositivNegativ";
import Kommentar from "./components/Kommentar";
import { HøringsNotatViewModel } from "../../../../ApiClients";
import { scrollToId, ScrollType } from "../../../../utils/scrollUtils";

const NotatUttalelse = ({
  forhandsvisning = false,
  notat,
  ingenMerknader,
  editManueltRegistert,
  ...rest
}: {
  notat: HøringsNotatViewModel;
  readonly: boolean;
  ingenMerknader: boolean;
  forhandsvisning?: boolean;
  editManueltRegistert: boolean;
}) => {
  const { kanHaGenerellHoldning, kanHaGenerellKommentar, id } = notat;

  if (!kanHaGenerellHoldning && !kanHaGenerellKommentar) return null;

  const readonly = rest.readonly && (!editManueltRegistert || ingenMerknader);

  return (
    <div key={`notatuttalelse-${id}`} className="Notatuttalelse">
      <div className="Sporsmal" id={`notat_${id}`}>
        <Element name={scrollToId(ScrollType.NOTAT, id)} />
        <div>
          {kanHaGenerellHoldning ? (
            <PositivNegativSvar
              id={id}
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              generell
            />
          ) : null}

          {kanHaGenerellKommentar ? (
            <Kommentar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              id={id}
              generell
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NotatUttalelse;
