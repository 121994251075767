import * as React from "react";
import { MultiSelect } from "@utdanningsdirektoratet/multiselect";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import Obligatorisk from "./Obligatorisk";
import {
  FylkeKommuneSvarMulighetViewModel,
  FylkeKommuneTypes,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import { useFylker, useUttalelse } from "../../../../../hooks";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";

const FylkeKommuneSvar = ({
  id,
  readonly,
  fylkeKommune,
  forhandsvisning,
}: {
  id: number;
  readonly: boolean;
  fylkeKommune: FylkeKommuneSvarMulighetViewModel;
  forhandsvisning: boolean;
}) => {
  const { data: uttalelse } = useUttalelse();
  const { data: fylker = [] } = useFylker();
  const intl = useIntl();
  const { fields = {} } =
    useForm<
      Record<SpørsmålFormId, SpørsmålUttalelseViewModel["fylkeKommune"]>
    >();

  const field = fields[getSpørsmålFormId(id, "fylkeKommune")] ?? {
    value:
      uttalelse?.spørsmål.find((s) => s.spørsmålId === id)?.fylkeKommune ?? [],
  };

  const selectKommuner =
    fylkeKommune.type === FylkeKommuneTypes.Kommune ||
    fylkeKommune.type === FylkeKommuneTypes.FylkeKommune;

  const fylke =
    field.value && fylkeKommune.flereFylker !== true && field.value.length > 0
      ? field.value[0].substring(0, 2)
      : "";

  const valgtFylke = fylker.find((f) => f.fylkesNr === fylke);
  const valgtKommune = React.useCallback(
    (fylkesNr: string, kommuneNr: string) =>
      fylker
        .find((f) => f.fylkesNr === fylkesNr)!
        .kommuner.find(
          (k) =>
            k.kommuneNr !== kommuneNr && field.value?.includes(k.kommuneNr),
        ),
    [field.value, fylker],
  );

  const fylkeOptions = React.useMemo(
    () =>
      fylker
        .filter((f) => f.aktiv)
        .reduce(
          (acc, f) => ({
            ...acc,
            [f.navn]: {
              value: f.fylkesNr,
              disabled:
                valgtFylke != null && valgtFylke.fylkesNr !== f.fylkesNr,
              options: !selectKommuner
                ? []
                : (f.kommuner || [])
                    .filter((k) => k.aktiv)
                    .map((k) => ({
                      text: k.navn,
                      value: k.kommuneNr,
                      disabled:
                        fylkeKommune.flereKommuner !== true &&
                        valgtKommune(f.fylkesNr, k.kommuneNr) != null,
                    })),
            },
          }),
          {},
        ),
    [
      fylker,
      valgtFylke,
      selectKommuner,
      fylkeKommune.flereKommuner,
      valgtKommune,
    ],
  );

  const valgteFylker = fylker.filter(
    (f) =>
      field.value?.includes(f.fylkesNr) ||
      f.kommuner.some((k) => field.value?.includes(k.kommuneNr)),
  );
  const valgteKommuner = fylker
    .flatMap((f) => f.kommuner)
    .filter((k) => field.value?.includes(k.kommuneNr))
    .map((m) => m.navn);

  let valgte = [] as Array<string>;
  if (fylkeKommune.type === FylkeKommuneTypes.FylkeKommune)
    valgte = [...valgteFylker.map((f) => f.navn), ...valgteKommuner];
  if (fylkeKommune.type === FylkeKommuneTypes.Fylke)
    valgte = valgteFylker.map((f) => f.navn);
  if (fylkeKommune.type === FylkeKommuneTypes.Kommune) valgte = valgteKommuner;

  let placeholder = "formLabels.velgFylkeKommune";
  if (fylkeKommune.type === FylkeKommuneTypes.Kommune)
    placeholder = "formLabels.velgKommune";
  if (fylkeKommune.type === FylkeKommuneTypes.Fylke)
    placeholder = "formLabels.velgFylke";

  const listClass = cls({
    "Sporsmal-liste": field.value?.some((s) => s.length === 4),
  });

  if (!forhandsvisning && readonly) {
    if (field.value) {
      return (
        <div className="Sporsmal-body">
          <ul className={listClass}>
            {valgteFylker.map((f) => {
              const _valgteKommuner = f.kommuner.filter((k) =>
                field.value?.includes(k.kommuneNr),
              );
              return (
                <li key={f.fylkesNr} className="Sporsmal-list-item">
                  <strong>{f.navn}</strong>
                  {_valgteKommuner.length > 0 && (
                    <ul>
                      {_valgteKommuner.map((k) => (
                        <li key={k.kommuneNr}>{k.navn}</li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittFylkeKommune" />
      </div>
    );
  }

  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={fylkeKommune.isObligatorisk}>
        <MultiSelect
          {...field}
          className="Sporsmal-fylkeKommune"
          label=""
          placeholder={
            valgte.sort().join(", ") ||
            `— ${intl.formatMessage({ id: placeholder })} —`
          }
          options={fylkeOptions}
          disabled={readonly}
        />
      </Obligatorisk>
    </div>
  );
};

export default FylkeKommuneSvar;
