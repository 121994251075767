import { FormValidation } from "@utdanningsdirektoratet/form";
import { Select } from "@utdanningsdirektoratet/select";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import type { FieldType } from "../SendInnPopin";

import { MalgruppeViewModel } from "../../../../../ApiClients";

const fieldsToReset = [
  "representerer",
  "stilling",
  "fylke",
  "kommune",
  "undertype",
  "bekreftRepresentasjon",
] as Array<keyof FieldType>;

const Sektor = ({
  fields,
  malgrupper,
  resetFields,
}: {
  fields: FormValidation<FieldType>["fields"];
  resetFields: FormValidation<FieldType>["resetFields"];
  malgrupper: Array<MalgruppeViewModel>;
}) => {
  const intl = useIntl();
  if (malgrupper.length <= 1) return null;

  const onChangeWrapped = (event: React.ChangeEvent<HTMLSelectElement>) => {
    resetFields(fieldsToReset);
    fields.sektor.onChange(event);
    if (event.target.value)
      fields.malgruppe.onChange(
        malgrupper.filter((m) => m.sektor === event.target.value)[0].id,
      );
  };

  const options = [...new Set(malgrupper.map((m) => m.sektor).sort())].map(
    (sektor) => ({
      value: sektor,
      text: `${intl.formatMessage({ id: `sendInnPopin.${sektor.toLowerCase()}` })}`,
    }),
  );

  const withDefault = [
    {
      value: "",
      text: `— ${intl.formatMessage({ id: "sendInnPopin.velg" })} ${intl.formatMessage({ id: "sendInnPopin.sektor" }).toLowerCase()} —`,
    },
    ...options,
  ];

  return (
    <div className="SendInnPopin-flexInput">
      <Element name="sektor" />
      <Select
        {...fields.sektor}
        className="SendInnPopin-select"
        onChange={onChangeWrapped}
        options={withDefault}
        id="minSektor"
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgSektor" })}
      />
    </div>
  );
};

export default Sektor;
