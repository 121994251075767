import { createContext, useReducer, useContext } from "react";
import { Målform } from "./ApiClients";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

const SET_LOCALE = "SET_LOCALE";
const SET_AUTOSAVING = "SET_AUTOSAVING";
const SET_PENDINGCHANGES = "SET_PENDINGCHANGES";
const SET_INGENMERKNADER = "SET_INGENMERKNADER";

export type ReducerStateProps = {
  locale: Målform;
  autosaving: boolean | undefined;
  pendingchanges: string | undefined;
  ingenMerknader: boolean;
};

export type ActionTypeProps =
  | {
      type: typeof SET_LOCALE;
      payload: Målform;
    }
  | {
      type: typeof SET_PENDINGCHANGES;
      payload: string;
    }
  | {
      type: typeof SET_AUTOSAVING;
      payload: boolean | undefined;
    }
  | {
      type: typeof SET_INGENMERKNADER;
      payload: boolean;
    };

const initialState = {
  locale: Målform.Bokmål,
  pendingchanges: undefined,
  autosaving: undefined,
  ingenMerknader: false,
} satisfies ReducerStateProps;

const reducer: React.Reducer<ReducerStateProps, ActionTypeProps> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, locale: action.payload };
    case SET_AUTOSAVING:
      return {
        ...state,
        autosaving: action.payload,
        pendingchanges:
          action.payload === true ? state.pendingchanges : undefined,
      };
    case SET_PENDINGCHANGES:
      return { ...state, pendingchanges: action.payload };
    case SET_INGENMERKNADER:
      return { ...state, ingenMerknader: action.payload };
    default:
      return state;
  }
};

export type UseStateProps = [
  ReducerStateProps,
  React.Dispatch<ActionTypeProps>,
];

export const StateContext = createContext<UseStateProps>([
  initialState,
  () => null,
]);
export const useContextState = (): UseStateProps => useContext(StateContext);

type StateProviderProps = {
  children: React.ReactNode;
};

const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
  return (
    <StateContext.Provider
      value={useReducer<React.Reducer<ReducerStateProps, ActionTypeProps>>(
        reducer,
        initialState,
      )}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
