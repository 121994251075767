import { ValidationType } from "@utdanningsdirektoratet/form";
import { IntlShape } from "react-intl";
import {
  EnigUenig,
  HøringsNotatUttalelseViewModel,
  JaNei,
  PositivNegativHoldning,
  SeksjonUttalelseViewModel,
  SpørsmålUttalelseVedleggViewModel,
  SpørsmålUttalelseViewModel,
  SvarPåAlternativViewModel,
} from "../ApiClients";

export const angittPositivNegativ = (u?: PositivNegativHoldning) =>
  u && u !== PositivNegativHoldning.IkkeAngitt;
export const angittJaNei = (u?: JaNei) => u && u !== JaNei.IkkeAngitt;
export const angittEnigUenig = (u?: EnigUenig) =>
  u && u !== EnigUenig.IkkeAngitt;
export const angittEttValg = (u?: Array<SvarPåAlternativViewModel>) =>
  u && u.length > 0 && u.some((spsa) => spsa.flereValg === false);
export const angittKommentar = (u?: string | null) => u && u.trim();
export const angittFlereValg = (u?: Array<SvarPåAlternativViewModel>) =>
  u && u.length > 0 && u.some((spsa) => spsa.flereValg === true);
export const angittVedlegg = (u?: Array<SpørsmålUttalelseVedleggViewModel>) =>
  u && u.length > 0;
export const angittFylke = (u?: Array<string> | null) =>
  u && u.filter((f) => f.length === 2).length > 0;
export const angittKommune = (u?: Array<string> | null) =>
  u && u.filter((f) => f.length === 4).length > 0;
export const angittFylkeKommune = (u?: Array<string> | null) =>
  u && u.length > 0;
export const angittKortTekst = (u?: string | null) => u && u.trim();
export const angittDato = (u?: Date | null) => !!u;
export const angittHeltall = (u?: number | null) => !!u || u === 0;

export const gyldigHetall = (u?: number | null) =>
  !u || u === 0 || (u >= 0 && u <= 2147483647 && Number.isInteger(u));

export const gyldigKortTekst = (
  u: string | null | undefined,
  validator: (input: unknown, values?: unknown) => boolean,
) => !angittKortTekst(u) || validator(u);

export const isSporsmalBesvart = (uttalelse?: SpørsmålUttalelseViewModel) =>
  angittPositivNegativ(uttalelse?.positivNegativHoldning) ||
  angittJaNei(uttalelse?.jaNei) ||
  angittEnigUenig(uttalelse?.enigUenig) ||
  angittEttValg(uttalelse?.svarPåSvarAlternativer) ||
  angittFlereValg(uttalelse?.svarPåSvarAlternativer) ||
  angittKommentar(uttalelse?.kommentar) ||
  angittVedlegg(uttalelse?.vedlegg) ||
  angittHeltall(uttalelse?.heltall) ||
  angittKortTekst(uttalelse?.kortTekst) ||
  angittDato(uttalelse?.dato) ||
  angittFylkeKommune(uttalelse?.fylkeKommune);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isSeksjonBesvart = (_uttalelse: SeksjonUttalelseViewModel) =>
  false;

export const isNotatBesvart = (uttalelse: HøringsNotatUttalelseViewModel) =>
  angittPositivNegativ(uttalelse.generellHoldning) ||
  angittKommentar(uttalelse.generellKommentar);

export const epostValidation = (
  intl: IntlShape,
): ValidationType<unknown, unknown> => ({
  validation: (input) => window.epostRegex.test(input as string),
  message: intl.formatMessage({ id: "validation.maVaereGyldigEpostadresse" }),
});
