import { FormValidation } from "@utdanningsdirektoratet/form";
import { Select } from "@utdanningsdirektoratet/select";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import type { FieldType } from "../SendInnPopin";
import {
  GeografiObligatoriskhet,
  MalgruppeViewModel,
} from "../../../../../ApiClients";
import { useFylker } from "../../../../../hooks";

const selectHasValue = (value: string) => value && value !== "null";

const Kommune = ({
  fields,
  valgtMalgruppe,
}: {
  fields: FormValidation<FieldType>["fields"];
  valgtMalgruppe: MalgruppeViewModel;
}) => {
  const { data: fylker } = useFylker();
  const visKommune =
    valgtMalgruppe.geografiObligatoriskhet ===
      GeografiObligatoriskhet.FylkeOgKommuneObligatorisk &&
    selectHasValue(fields.fylke.value);

  const intl = useIntl();
  if (!visKommune || !fylker) return null;

  const valgtFylke = fylker.find(
    (fylke) => fylke.fylkesNr === fields.fylke.value,
  )!;

  const gjeldendeKommuner = valgtFylke.kommuner
    .filter((f) => f.aktiv)
    .map((kommune) => (
      <option key={kommune.kommuneNr} value={kommune.kommuneNr}>
        {kommune.navn}
      </option>
    ));
  const gamleKommuner = valgtFylke.kommuner
    .filter((f) => !f.aktiv)
    .map((kommune) => (
      <option key={kommune.kommuneNr} value={kommune.kommuneNr}>
        {kommune.navn}
      </option>
    ));

  return (
    <div className="SendInnPopin-flexInput">
      <Element name="kommune" />
      <Select
        className="SendInnPopin-select"
        {...fields.kommune}
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgKommune" })}
      >
        <option value="">{`— ${intl.formatMessage({ id: "sendInnPopin.velgKommune" })} —`}</option>
        {gamleKommuner.length > 0 && gjeldendeKommuner.length > 0 ? (
          <optgroup
            label={intl.formatMessage({ id: "sendInnPopin.gjeldendeKommuner" })}
          >
            {gjeldendeKommuner}
          </optgroup>
        ) : (
          gjeldendeKommuner
        )}
        {gamleKommuner.length > 0 && gjeldendeKommuner.length > 0 ? (
          <optgroup
            label={intl.formatMessage({ id: "sendInnPopin.gamleKommuner" })}
          >
            {gamleKommuner}
          </optgroup>
        ) : (
          gamleKommuner
        )}
      </Select>
    </div>
  );
};

export default Kommune;
