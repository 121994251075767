import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Element } from "react-scroll";
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect } from "react";
import { show, usePopin } from "@utdanningsdirektoratet/popin";
import { debounce } from "lodash";
import {
  MappedProps,
  useFormValidation,
  Form,
  validationFunctions,
} from "@utdanningsdirektoratet/form";
import { useIntl } from "react-intl";
import { isValidDate } from "@utdanningsdirektoratet/utils/date";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import InnsendtHeader from "./components/hoeringsUttalelse/InnsendtHeader";
import IngenMerknader from "./components/hoeringsUttalelse/IngenMerknader";
import IngenMerknaderManueltRegistrert from "./components/hoeringsUttalelse/IngenMerknaderManueltRegistrert";
import Innholdsfortegnelse from "./components/Innholdsfortegnelse";
import LagreOgGaTilbake from "./components/hoeringsUttalelse/LagreOgGaTilbake";
import LagreOgSendInn from "./components/hoeringsUttalelse/LagreOgSendInn";
import SeksjonUttalelse from "./components/uttalelse/SeksjonUttalelse";
import NotatUttalelse from "./components/uttalelse/NotatUttalelse";
import Signatur from "./components/hoeringsUttalelse/Signatur";
import Fotnoter from "./components/hoeringsUttalelse/Fotnoter";
import Vedlegg from "./components/hoeringsUttalelse/Vedlegg";
import SendInnPopin from "./components/sendInn/SendInnPopin";
import LagreUtkastPopin from "./components/lagreUtkast/LagreUtkastPopin";
import VideresendUtkastPopin from "./components/videresendUtkast/VideresendUtkastPopin";
import MessageBanner from "./components/hoeringsUttalelse/MessageBanner";
import Header from "./components/hoeringsUttalelse/Header";
import Sidebar from "./components/sidebar/Sidebar";
import {
  EnigUenig,
  FylkeKommuneTypes,
  HoeringClient,
  JaNei,
  KortSvarTypes,
  PositivNegativHoldning,
  PubliseringsStatus,
  SpørsmålUttalelseVedleggViewModel,
  SvarPåAlternativViewModel,
} from "../../ApiClients";
import {
  useAutoSave,
  useHoering,
  useLeseTilgangsKode,
  useLocale,
  useManuellRegistreringsKode,
  useNotatId,
  useUttalelse,
} from "../../hooks";
import {
  getNextNotat,
  getNotat,
  getNotatFormId,
  getSpørsmålFormId,
  NotatFormId,
  SpørsmålFormId,
} from "../../utils/hoeringUtils";
import DocumentMetadata from "../../components/DocumentMetadata";
import { features, institusjonNavnLang } from "../../logic/initialState";
import AutoSaveLoader from "../../components/AutoSaveLoader";
import {
  getDefaultLocaleFromMalforms,
  getTextWithLocale,
} from "../../utils/localeUtils";
import {
  navigateToFootnote,
  navigateToNotat,
} from "../../utils/navigationUtils";
import { emptyGuid, useContextState } from "../../StateProvider";
import {
  addOrUpdateUtkast,
  getUtkasts,
  removeUtkast,
} from "../../utils/localStorageUtils";
import { routes } from "../../routes";
import {
  angittDato,
  angittEnigUenig,
  angittEttValg,
  angittFlereValg,
  angittFylke,
  angittFylkeKommune,
  angittHeltall,
  angittJaNei,
  angittKommentar,
  angittKommune,
  angittKortTekst,
  angittPositivNegativ,
  angittVedlegg,
  gyldigHetall,
  gyldigKortTekst,
} from "../../logic/validation";
import HoeringsFooter from "../../components/Footer";

const client = new HoeringClient();

function filterFalsy<T extends Record<string, unknown>>(obj: T) {
  return Object.keys(obj).reduce<T>((acc, key) => {
    if (obj[key]) {
      return {
        ...acc,
        [key]: obj[key],
      };
    }

    return acc;
  }, {} as T);
}

const HoeringsUttalelse = ({ utkastPopinId }: { utkastPopinId: string }) => {
  const locale = useLocale();
  const { data: uttalelse, mutate: mutateUttalelse } = useUttalelse();
  const { data: hoering, mutate: mutateHoering } = useHoering();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useSearchParams();
  const leseTilgangsKode = useLeseTilgangsKode();
  const [{ ingenMerknader, autosaving, pendingchanges }, dispatch] =
    useContextState();
  const { trigger } = useAutoSave();
  const intl = useIntl();
  const manuellRegistreringsKode = useManuellRegistreringsKode();
  let notatId = +(useNotatId() ?? NaN);
  if (!notatId) {
    const notatsWithFeedback =
      hoering.notater.filter(
        (n) =>
          uttalelse?.notater?.find((hnu) => hnu.notatId === n.id)?.hasFeedback,
      ) ?? [];
    notatId =
      notatsWithFeedback.length > 0
        ? notatsWithFeedback[0].id
        : hoering.notater.sort((a, b) => a.rekkefølge - b.rekkefølge)[0]?.id;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((callback: () => void) => {
      callback();
    }, 2000),
    [],
  );

  const lukketMenKanSendesInn =
    hoering.isClosedOrExpired && hoering.lukketInnsending;
  const readOnly =
    !lukketMenKanSendesInn &&
    (hoering.isLukketForUttalelser ||
      uttalelse?.manueltRegistrert ||
      hoering.publiseringsStatus !== PubliseringsStatus.PublisertÅpen ||
      hoering.isClosedOrExpired ||
      !!leseTilgangsKode);

  const currentNotat = getNotat(notatId, hoering);
  const nextNotat = getNextNotat(notatId, hoering);

  const [sendInnPopinId] = usePopin();
  const [lagreUtkastPopinId] = usePopin();
  const [videresendUtkastPopinId] = usePopin();

  const { id, publiseringsStatus, frist, publisert } = hoering;
  const {
    eksternId,
    innsendt = false,
    manueltRegistrert = false,
  } = uttalelse ?? {};
  const editManueltRegistert = manueltRegistrert && !!manuellRegistreringsKode;
  const hoeringRoute = useMatch(routes.hoering(":hoeringId"));
  const utkastRoute = useMatch(routes.utkast(":hoeringId", ":eksternId"));

  const formValidation = useFormValidation(
    filterFalsy<MappedProps<Record<NotatFormId | SpørsmålFormId, unknown>>>({
      ...hoering.notater.reduce<MappedProps<Record<NotatFormId, unknown>>>(
        (acc, curr) =>
          ({
            ...acc,
            [getNotatFormId(curr.id, "generellHoldning")]:
              curr.kanHaGenerellHoldning
                ? {
                    value:
                      uttalelse?.notater.find((n) => n.notatId === curr.id)
                        ?.generellHoldning ?? PositivNegativHoldning.IkkeAngitt,
                    validation: [
                      {
                        validation: (
                          value: PositivNegativHoldning,
                          values: Record<NotatFormId, string>,
                        ) =>
                          !(
                            angittKommentar(
                              values[
                                getNotatFormId(curr.id, "generellKommentar")
                              ],
                            ) && !angittPositivNegativ(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiPositivNegativ",
                        }),
                      },
                    ],
                  }
                : undefined,
            [getNotatFormId(curr.id, "generellKommentar")]:
              curr.kanHaGenerellKommentar
                ? {
                    value:
                      uttalelse?.notater.find((n) => n.notatId === curr.id)
                        ?.generellKommentar ?? "",
                  }
                : undefined,
          }) satisfies MappedProps<Record<NotatFormId, unknown>>,
        {},
      ),
      ...hoering.notater
        .flatMap((n) => n.seksjoner)
        .flatMap((s) => s.spørsmål)
        .reduce<MappedProps<Record<SpørsmålFormId, unknown>>>(
          (acc, curr) =>
            ({
              ...acc,
              [getSpørsmålFormId(curr.id, "positivNegativHoldning")]: curr
                .positivNegativ.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.positivNegativHoldning ??
                      PositivNegativHoldning.IkkeAngitt,
                    validation: [
                      {
                        validation: (
                          value: PositivNegativHoldning,
                          values: Record<SpørsmålFormId, string>,
                        ) =>
                          !(
                            (curr.positivNegativ.isObligatorisk ||
                              angittKommentar(
                                values[getSpørsmålFormId(curr.id, "kommentar")],
                              )) &&
                            !angittPositivNegativ(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiPositivNegativ",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "jaNei")]: curr.jaNei.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.jaNei ?? JaNei.IkkeAngitt,
                    validation: [
                      {
                        validation: (
                          value: JaNei,
                          values: Record<SpørsmålFormId, string>,
                        ) =>
                          !(
                            (curr.jaNei.isObligatorisk ||
                              angittKommentar(
                                values[getSpørsmålFormId(curr.id, "kommentar")],
                              )) &&
                            !angittJaNei(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiJaNei",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "enigUenig")]: curr.enigUenig.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.enigUenig ?? EnigUenig.IkkeAngitt,
                    validation: [
                      {
                        validation: (
                          value: EnigUenig,
                          values: Record<SpørsmålFormId, string>,
                        ) =>
                          !(
                            (curr.enigUenig.isObligatorisk ||
                              angittKommentar(
                                values[getSpørsmålFormId(curr.id, "kommentar")],
                              )) &&
                            !angittEnigUenig(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiEnigUenig",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "heltall")]: curr.heltall.canHave
                ? {
                    value: `${
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.heltall ?? ""
                    }`,
                    validation: [
                      {
                        validation: (value: string) =>
                          !(
                            (curr.heltall.isObligatorisk &&
                              !angittHeltall(parseInt(value, 10))) ||
                            !gyldigHetall(parseInt(value, 10))
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiHeltall",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "svarPåSvarAlternativer")]: curr
                .svarAlternativer.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.svarPåSvarAlternativer ?? [],
                    validation: [
                      {
                        validation: (value: Array<SvarPåAlternativViewModel>) =>
                          !(
                            curr.svarAlternativer.isObligatorisk &&
                            !(curr.svarAlternativer.flereValg
                              ? angittFlereValg(value)
                              : angittEttValg(value)) &&
                            curr.svarAlternativer.svarAlternativ.length > 1
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiEttValg",
                        }),
                      },
                      {
                        validation: (value: Array<SvarPåAlternativViewModel>) =>
                          !(
                            curr.svarAlternativer.isObligatorisk &&
                            !(curr.svarAlternativer.flereValg
                              ? angittFlereValg(value)
                              : angittEttValg(value)) &&
                            curr.svarAlternativer.svarAlternativ.length <= 1
                          ),
                        message: intl.formatMessage({
                          id: "validation.vennligstKryssAv",
                        }),
                      },
                      {
                        validation: (value: Array<SvarPåAlternativViewModel>) =>
                          curr.svarAlternativer.svarAlternativ
                            .filter((alt) => alt.erEgendefinert)
                            .map((alt) => alt.id)
                            .filter((_id) =>
                              value.find(
                                (spsa) =>
                                  spsa.svarAlternativId === _id &&
                                  spsa.flereValg ===
                                    curr.svarAlternativer.flereValg,
                              ),
                            )
                            .map(
                              (_id) =>
                                value.find(
                                  (spsa) => spsa.svarAlternativId === _id,
                                )!,
                            )
                            .filter(
                              (spsa) =>
                                !(
                                  spsa.egendefinertSvar &&
                                  spsa.egendefinertSvar.trim()
                                ),
                            ).length === 0,
                        message: intl.formatMessage({
                          id: "validation.annetMaHaTekst",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "kortTekst")]: curr.kortTekst.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.kortTekst ?? "",
                    validation: [
                      {
                        validation: (value: string) => {
                          switch (curr.kortTekst.type) {
                            case KortSvarTypes.Kontonr:
                              return !(
                                (curr.kortTekst.isObligatorisk &&
                                  !angittKortTekst(value)) ||
                                !gyldigKortTekst(
                                  value,
                                  validationFunctions.kontonummer.validation,
                                )
                              );
                            case KortSvarTypes.Orgnr:
                              return !(
                                (curr.kortTekst.isObligatorisk &&
                                  !angittKortTekst(value)) ||
                                !gyldigKortTekst(
                                  value,
                                  validationFunctions.organisasjonsnummer
                                    .validation,
                                )
                              );
                            case KortSvarTypes.Epost:
                              return !(
                                (curr.kortTekst.isObligatorisk &&
                                  !angittKortTekst(value)) ||
                                !gyldigKortTekst(
                                  value,
                                  validationFunctions.email.validation,
                                )
                              );
                            case KortSvarTypes.Tekst:
                              return !(
                                curr.kortTekst.isObligatorisk &&
                                !angittKortTekst(value)
                              );
                            default:
                              return true;
                          }
                        },
                        message: () => {
                          switch (curr.kortTekst.type) {
                            case KortSvarTypes.Kontonr:
                              return intl.formatMessage({
                                id: "validation.oppgiKortTekstKontonr",
                              });
                            case KortSvarTypes.Orgnr:
                              return intl.formatMessage({
                                id: "validation.oppgiKortTekstOrgnr",
                              });
                            case KortSvarTypes.Epost:
                              return intl.formatMessage({
                                id: "validation.oppgiKortTekstEpost",
                              });
                            case KortSvarTypes.Tekst:
                              return intl.formatMessage({
                                id: "validation.oppgiKortTekst",
                              });
                            default:
                              return undefined;
                          }
                        },
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "dato")]: curr.dato.canHave
                ? {
                    value: uttalelse?.spørsmål.find(
                      (sp) => sp.spørsmålId === curr.id,
                    )?.dato,
                    validation: [
                      {
                        validation: (value: Date) =>
                          !(curr.dato.isObligatorisk && !angittDato(value)),
                        message: intl.formatMessage({
                          id: "validation.oppgiDato",
                        }),
                      },
                      {
                        validation: (value: Date) =>
                          !(curr.dato.isObligatorisk && !isValidDate(value)),
                        message: intl.formatMessage({
                          id: "validation.ugyldigDato",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "fylkeKommune")]: curr.fylkeKommune
                .canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.fylkeKommune ?? [],
                    validation: [
                      {
                        validation: (value: Array<string>) =>
                          !(
                            (curr.fylkeKommune.isObligatorisk ||
                              angittFylkeKommune(value)) &&
                            curr.fylkeKommune.type ===
                              FylkeKommuneTypes.Kommune &&
                            !angittKommune(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiKommune",
                        }),
                      },
                      {
                        validation: (value: Array<string>) =>
                          !(
                            (curr.fylkeKommune.isObligatorisk ||
                              angittFylkeKommune(value)) &&
                            curr.fylkeKommune.type ===
                              FylkeKommuneTypes.Fylke &&
                            !angittFylke(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiFylke",
                        }),
                      },
                      {
                        validation: (value: Array<string>) =>
                          !(
                            curr.fylkeKommune.isObligatorisk &&
                            curr.fylkeKommune.type ===
                              FylkeKommuneTypes.FylkeKommune &&
                            !angittFylkeKommune(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiFylkeKommune",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "vedlegg")]: curr.vedlegg.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find(
                        (sp) => sp.spørsmålId === curr.id,
                      )?.vedlegg ?? [],
                    validation: [
                      {
                        validation: (
                          value: Array<SpørsmålUttalelseVedleggViewModel>,
                        ) =>
                          !(
                            curr.vedlegg.isObligatorisk && !angittVedlegg(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiVedlegg",
                        }),
                      },
                    ],
                  }
                : undefined,
              [getSpørsmålFormId(curr.id, "kommentar")]: curr.kommentar.canHave
                ? {
                    value:
                      uttalelse?.spørsmål.find((n) => n.spørsmålId === curr.id)
                        ?.kommentar ?? "",
                    validation: [
                      {
                        validation: (value: string) =>
                          !(
                            curr.kommentar.isObligatorisk &&
                            !angittKommentar(value)
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiKommentar",
                        }),
                      },
                      {
                        validation: (
                          value: string,
                          values: Record<SpørsmålFormId, EnigUenig>,
                        ) =>
                          !(
                            !angittKommentar(value) &&
                            angittEnigUenig(
                              values[getSpørsmålFormId(curr.id, "enigUenig")],
                            ) &&
                            curr.kommentar.obligatoriskKommentarEnigUenig?.some(
                              (s) =>
                                s ===
                                values[getSpørsmålFormId(curr.id, "enigUenig")],
                            )
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiKommentar",
                        }),
                      },
                      {
                        validation: (
                          value: string,
                          values: Record<SpørsmålFormId, JaNei>,
                        ) =>
                          !(
                            !angittKommentar(value) &&
                            angittJaNei(
                              values[getSpørsmålFormId(curr.id, "jaNei")],
                            ) &&
                            curr.kommentar.obligatoriskKommentarJaNei?.some(
                              (s) =>
                                s ===
                                values[getSpørsmålFormId(curr.id, "jaNei")],
                            )
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiKommentar",
                        }),
                      },
                      {
                        validation: (
                          value: string,
                          values: Record<
                            SpørsmålFormId,
                            PositivNegativHoldning
                          >,
                        ) =>
                          !(
                            !angittKommentar(value) &&
                            angittPositivNegativ(
                              values[
                                getSpørsmålFormId(
                                  curr.id,
                                  "positivNegativHoldning",
                                )
                              ],
                            ) &&
                            curr.kommentar.obligatoriskKommentarPositivNegativ?.some(
                              (s) =>
                                s ===
                                values[
                                  getSpørsmålFormId(
                                    curr.id,
                                    "positivNegativHoldning",
                                  )
                                ],
                            )
                          ),
                        message: intl.formatMessage({
                          id: "validation.oppgiKommentar",
                        }),
                      },
                    ],
                  }
                : undefined,
            }) satisfies MappedProps<Record<SpørsmålFormId, unknown>>,
          {},
        ),
    } satisfies MappedProps<Record<NotatFormId | SpørsmålFormId, unknown>>),
    {
      onChange: () => {
        dispatch({
          type: "SET_PENDINGCHANGES",
          payload: eksternId ?? emptyGuid,
        });
      },
    },
  );

  useEffect(() => {
    // Set locale if default doesnt exist in høring
    if (!hoering.målforms.includes(locale)) {
      dispatch({
        type: "SET_LOCALE",
        payload: getDefaultLocaleFromMalforms(hoering.målforms),
      });
    }
  }, [dispatch, hoering.målforms, locale]);

  useEffect(() => {
    // Set locale if default doesnt match usermålform
    if (uttalelse && uttalelse.innsendt && uttalelse.userMålform !== locale) {
      dispatch({
        type: "SET_LOCALE",
        payload: uttalelse.userMålform,
      });
    }
  }, [dispatch, locale, uttalelse]);

  useEffect(() => {
    // Show utkast popin if utkasts exist
    const utkasts = getUtkasts(hoering.id);
    const showUtkastPopin =
      !uttalelse?.innsendt &&
      hoering.publiseringsStatus === PubliseringsStatus.PublisertÅpen &&
      utkasts.length > 0 &&
      !!hoeringRoute;
    if (showUtkastPopin) show(utkastPopinId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Refresh høring if path is changed
    if (location.pathname) mutateHoering();
  }, [location.pathname, mutateHoering]);

  useEffect(() => {
    // Reset saving and ingenMerknader on uttalelse changes
    if (!uttalelse || uttalelse.eksternId === emptyGuid) {
      dispatch({ type: "SET_AUTOSAVING", payload: undefined });
      dispatch({ type: "SET_INGENMERKNADER", payload: false });
    }
  }, [dispatch, uttalelse]);

  useEffect(() => {
    if (searchParams[0].get("bekreftet")) {
      newNotification(
        "success",
        intl.formatMessage({ id: "uttalelse.uttalelseBekreftet" }),
        intl.formatMessage({ id: "uttalelse.uttalelseVilBliBehandlet" }),
      );
      searchParams[1]((current) => {
        current.delete("bekreftet");
        return current;
      });
    }
  }, [intl, searchParams]);

  useEffect(() => {
    // Navigate to uttalelse if utkast is already submitted
    if (innsendt && !!utkastRoute) {
      removeUtkast(id, eksternId);
      navigate(routes.uttalelse(id, eksternId!));
    }
  }, [eksternId, id, innsendt, navigate, utkastRoute]);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const { target } = e;
      if (target instanceof HTMLElement) {
        if (
          target.classList.contains("fotnote") ||
          (target.dataset as { fotnoteId: string }).fotnoteId
        ) {
          e.preventDefault();
          navigateToFootnote(
            (target.dataset as { fotnoteId: string }).fotnoteId,
            currentNotat.id,
          );
        }
      }
    };
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [currentNotat]);

  useEffect(() => {
    if (!currentNotat && hoering.notater.length > 0) {
      navigateToNotat(
        searchParams,
        hoering.notater.sort((a, b) => a.rekkefølge - b.rekkefølge)[0].id,
      );
    }
  }, [currentNotat, hoering.notater, searchParams]);

  const autosave = useCallback(
    async (immediate = false) => {
      if (
        !uttalelse?.innsendt &&
        (uttalelse?.eksternId === pendingchanges || immediate)
      ) {
        try {
          const result = await trigger(formValidation.values, {
            revalidate: false,
          });
          await mutateUttalelse();
          addOrUpdateUtkast(result.hoeringId, {
            endret: result.endret,
            eksternId: result.eksternId,
          });
          dispatch({
            type: "SET_AUTOSAVING",
            payload: false,
          });
          navigate(
            {
              pathname: routes.utkast(result.hoeringId, result.eksternId),
              search: searchParams[0].toString(),
            },
            {
              replace: true,
            },
          );
          return result.eksternId;
        } catch (e) {
          dispatch({
            type: "SET_AUTOSAVING",
            payload: undefined,
          });
          return eksternId ?? emptyGuid;
        }
      }
      return eksternId ?? emptyGuid;
    },
    [
      dispatch,
      eksternId,
      formValidation.values,
      mutateUttalelse,
      navigate,
      pendingchanges,
      searchParams,
      trigger,
      uttalelse?.eksternId,
      uttalelse?.innsendt,
    ],
  );

  useEffect(() => {
    if (autosaving) {
      autosave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosaving]);

  useEffect(() => {
    if (
      formValidation &&
      !innsendt &&
      pendingchanges &&
      eksternId === pendingchanges
    ) {
      debouncedSave(() => {
        dispatch({
          type: "SET_AUTOSAVING",
          payload: true,
        });
      });
    }
    return debouncedSave.cancel;
  }, [
    debouncedSave,
    dispatch,
    eksternId,
    innsendt,
    pendingchanges,
    formValidation,
  ]);

  const pageClass = cls({
    Page: true,
    "Page--readonly": readOnly || innsendt,
    "Page--innsendt": innsendt && !editManueltRegistert,
  });

  if (
    !hoering.forhåndsvisnings &&
    publiseringsStatus === PubliseringsStatus.IkkePublisert
  ) {
    return (
      <>
        <DocumentMetadata title={institusjonNavnLang} />
        <div className="Grid">
          <div className={pageClass}>
            <Element name="headerScroll" />
            <Header />
            <MessageBanner />
          </div>
        </div>
        <HoeringsFooter hoeringId={hoering.id} />
      </>
    );
  }

  if (!currentNotat) return null;

  const { arkivDisplayNummer, saksBehandlereTekst, tittel } = currentNotat;

  const arkivnummer =
    innsendt &&
    !editManueltRegistert &&
    features[hoering.mode].enSakPerSvarInnstilling
      ? uttalelse?.arkivnummer
      : arkivDisplayNummer;

  const hasSidebar =
    hoering.notater.filter(
      (n) =>
        !innsendt ||
        uttalelse?.notater.find((hnu) => hnu.notatId === n.id)?.hasFeedback ||
        n.rekkefølge === 0,
    ).length > 1;

  const gridClass = cls({
    Grid: true,
    "Grid--offset":
      hasSidebar || (editManueltRegistert && hoering.notater.length > 1),
    "Grid--innsendt": innsendt && !editManueltRegistert,
  });

  return (
    <>
      <DocumentMetadata title={getTextWithLocale(tittel, locale)} />
      <AutoSaveLoader />
      <div className={gridClass}>
        <Sidebar
          currentNotat={currentNotat}
          notater={hoering.notater}
          innsendt={innsendt && !editManueltRegistert}
          showTimestamp={!innsendt && !readOnly}
        />
        <div className={pageClass}>
          <header>
            <Element name="headerScroll" />
            <InnsendtHeader show={innsendt && !editManueltRegistert} />
            <Header
              avsender={saksBehandlereTekst}
              frist={frist}
              publisert={publisert}
              referanse={arkivnummer}
            />
          </header>
          <main id="main">
            <div className="Page-section">
              <h1 className="Page-title u-pageTitle">
                {getTextWithLocale(tittel, locale)}
              </h1>
            </div>

            <MessageBanner editManueltRegistert={editManueltRegistert} />

            {editManueltRegistert ? (
              <IngenMerknaderManueltRegistrert
                onClick={async (_ingenMerknader) => {
                  dispatch({ type: "SET_INGENMERKNADER", payload: true });
                  await client.setIngenMerknader(
                    eksternId!,
                    manuellRegistreringsKode,
                    _ingenMerknader,
                  );
                  await mutateUttalelse();
                }}
                kanHaIngenMerknader={hoering.kanHaIngenMerknader}
                harIngenMerknader={ingenMerknader}
              />
            ) : (
              <IngenMerknader
                readonly={innsendt || readOnly}
                onClick={() => {
                  dispatch({ type: "SET_INGENMERKNADER", payload: true });
                  show(sendInnPopinId);
                }}
                hasFeedback={uttalelse?.hasFeedback}
                kanHaIngenMerknader={hoering.kanHaIngenMerknader}
                harIngenMerknader={ingenMerknader}
              />
            )}

            {currentNotat.visInnholdsfortegnelse && (
              <Innholdsfortegnelse
                seksjoner={currentNotat.seksjoner}
                skjulNummerering={currentNotat.skjulNummerering}
              />
            )}

            <Form {...formValidation}>
              <SeksjonUttalelse
                readonly={innsendt || readOnly}
                editManueltRegistert={editManueltRegistert}
                seksjons={currentNotat.seksjoner}
                ingenMerknader={ingenMerknader}
                skjulNummerering={currentNotat.skjulNummerering}
                autosave={autosave}
                forhandsvisning={
                  (hoering.isClosedOrExpired && !innsendt) ||
                  hoering.forhåndsvisnings ||
                  editManueltRegistert
                }
              />

              <NotatUttalelse
                readonly={innsendt || readOnly}
                editManueltRegistert={editManueltRegistert}
                notat={currentNotat}
                ingenMerknader={ingenMerknader}
                forhandsvisning={
                  (hoering.isClosedOrExpired && !innsendt) ||
                  hoering.forhåndsvisnings ||
                  editManueltRegistert
                }
              />

              <Signatur signatur={currentNotat.signaturSeksjon} />

              <Fotnoter
                fotnoter={currentNotat.fotnoter}
                notatId={currentNotat.id}
              />

              <Vedlegg
                vedlegg={hoering.vedlegg}
                notatVedlegg={currentNotat.vedlegg}
              />

              {editManueltRegistert ? (
                <LagreOgGaTilbake
                  manuellRegistreringsKode={manuellRegistreringsKode}
                  returnTo={searchParams[0].get("returnTo")!}
                />
              ) : (
                <LagreOgSendInn
                  innsendt={innsendt}
                  forhandsvisning={hoering.forhåndsvisnings}
                  hasFeedback={uttalelse?.hasFeedback}
                  readOnly={readOnly}
                  nextNotat={nextNotat}
                  currentNotat={currentNotat}
                  sendInnKunSisteNotat={
                    features[hoering.mode].sendInnKunSisteNotat
                  }
                  sendInnPopinId={sendInnPopinId}
                  lagreUtkastPopinId={lagreUtkastPopinId}
                  videresendUtkastPopinId={videresendUtkastPopinId}
                  autosave={autosave}
                />
              )}

              <SendInnPopin id={sendInnPopinId} />
            </Form>
            <LagreUtkastPopin id={lagreUtkastPopinId} />
            <VideresendUtkastPopin id={videresendUtkastPopinId} />
          </main>
        </div>
      </div>
      <HoeringsFooter hoeringId={hoering.id} />
    </>
  );
};

export default HoeringsUttalelse;
