import { FormValidation } from "@utdanningsdirektoratet/form";
import { useIntl } from "react-intl";
import Sektor from "./Sektor";
import Malgruppe from "./Malgruppe";
import InnsenderInfo from "./InnsenderInfo";
import {
  MalgruppeViewModel,
  Sektor as SektorEnum,
} from "../../../../../ApiClients";

import type { FieldType } from "../SendInnPopin";

const MalgruppeSektor = ({
  valgtMalgruppe,
  malgrupper,
  fields,
  resetFields,
}: {
  valgtMalgruppe?: MalgruppeViewModel;
  malgrupper: Array<MalgruppeViewModel>;
  fields: FormValidation<FieldType>["fields"];
  resetFields: FormValidation<FieldType>["resetFields"];
}) => {
  const intl = useIntl();
  const malgrupperForValgtSektor = malgrupper.filter(
    (malgruppe) =>
      !fields.sektor.value || malgruppe.sektor === fields.sektor.value,
  );
  const malgrupperGroupByName = malgrupper.reduce(
    (curr, val) => {
      if (
        fields.sektor.value === SektorEnum.Enkeltperson &&
        val.sektor === SektorEnum.Enkeltperson
      ) {
        if (!curr[val.tittel]) return { ...curr, [val.tittel]: [val] };
        return { ...curr, [val.tittel]: [...curr[val.tittel], val] };
      }
      if (
        fields.sektor.value !== SektorEnum.Enkeltperson &&
        val.sektor !== SektorEnum.Enkeltperson
      ) {
        if (!curr[val.tittel]) return { ...curr, [val.tittel]: [val] };
        return { ...curr, [val.tittel]: [...curr[val.tittel], val] };
      }
      return curr;
    },
    {} as { [key: string]: Array<MalgruppeViewModel> },
  );
  const malgrupperForValgtMalgruppe = valgtMalgruppe
    ? malgrupperGroupByName[valgtMalgruppe.tittel]
    : [];

  if (malgrupperForValgtSektor.length === 0) return null;

  return (
    <div>
      {(malgrupperForValgtSektor.length > 1 ||
        malgrupperForValgtMalgruppe.length > 1) && (
        <label className="SendInnPopin-label" htmlFor="velgMalgruppe">
          {intl.formatMessage({
            id:
              fields.sektor.value === SektorEnum.Enkeltperson
                ? "sendInnPopin.jegErEn"
                : "sendInnPopin.jegRepresenterer",
          })}
        </label>
      )}
      <div className="Grid-12-12 SendInnPopin-wrapper">
        {Object.keys(malgrupperGroupByName).length > 1 ? (
          <Malgruppe
            fields={fields}
            malgrupper={malgrupperGroupByName}
            alleMalgrupper={malgrupper}
            resetFields={resetFields}
          />
        ) : null}
        {fields.sektor.value !== SektorEnum.Enkeltperson ? (
          <Sektor
            malgrupper={malgrupperForValgtMalgruppe}
            fields={fields}
            resetFields={resetFields}
          />
        ) : null}
      </div>
      {fields.sektor.value && fields.malgruppe.value ? (
        <InnsenderInfo
          fields={fields}
          malgrupper={malgrupper}
          resetFields={resetFields}
        />
      ) : null}
    </div>
  );
};

export default MalgruppeSektor;
