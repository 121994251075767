import { Select } from "@utdanningsdirektoratet/select";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import { FormValidation } from "@utdanningsdirektoratet/form";
import type { FieldType } from "../SendInnPopin";
import { useFylker } from "../../../../../hooks";

const fieldsToReset = ["kommune"] as Array<keyof FieldType>;

const Fylke = ({
  fields,
  resetFields,
}: {
  fields: FormValidation<FieldType>["fields"];
  resetFields: FormValidation<FieldType>["resetFields"];
}) => {
  const intl = useIntl();

  const { data: fylker } = useFylker();

  if (!fylker) return null;

  const onChangeWrapped = (e: React.ChangeEvent) => {
    resetFields(fieldsToReset);
    fields.fylke.onChange(e);
  };

  const gjeldendeFylker = fylker
    .filter((f) => f.aktiv)
    .map((fylke) => (
      <option key={fylke.fylkesNr} value={fylke.fylkesNr}>
        {fylke.navn}
      </option>
    ));
  const gamleFylker = fylker
    .filter((f) => !f.aktiv)
    .map((fylke) => (
      <option key={fylke.fylkesNr} value={fylke.fylkesNr}>
        {fylke.navn}
      </option>
    ));

  return (
    <div className="SendInnPopin-flexInput">
      <Element name="fylke" />
      <Select
        {...fields.fylke}
        className="SendInnPopin-select"
        onChange={onChangeWrapped}
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgFylke" })}
      >
        <option value="">{`— ${intl.formatMessage({ id: "sendInnPopin.velgFylke" })} —`}</option>
        {gamleFylker.length > 0 && gjeldendeFylker.length > 0 ? (
          <optgroup
            label={intl.formatMessage({ id: "sendInnPopin.gjeldendeFylker" })}
          >
            {gjeldendeFylker}
          </optgroup>
        ) : (
          gjeldendeFylker
        )}
        {gamleFylker.length > 0 && gjeldendeFylker.length > 0 ? (
          <optgroup
            label={intl.formatMessage({ id: "sendInnPopin.gamleFylker" })}
          >
            {gamleFylker}
          </optgroup>
        ) : (
          gamleFylker
        )}
      </Select>
    </div>
  );
};

export default Fylke;
