import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import { FormValidation } from "@utdanningsdirektoratet/form";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { type FieldType } from "../SendInnPopin";
import { MalgruppeViewModel } from "../../../../../ApiClients";
import { useLocale } from "../../../../../hooks";
import { getTextWithLocale } from "../../../../../utils/localeUtils";

const BekreftRepresentasjon = ({
  fields,
  valgtMalgruppe,
  className,
}: {
  fields: FormValidation<FieldType>["fields"];
  valgtMalgruppe: MalgruppeViewModel;
  className?: string;
}) => {
  const intl = useIntl();
  const locale = useLocale();
  if (!valgtMalgruppe.bekreftRepresentasjon) return null;

  const onChangeWrapped = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    fields.bekreftRepresentasjon.onChange(checked);
  };

  const label = getTextWithLocale(
    valgtMalgruppe.bekreftRepresentasjonTekst,
    locale,
  )
    ? getTextWithLocale(valgtMalgruppe.bekreftRepresentasjonTekst, locale)
    : intl.formatMessage(
        { id: "sendInnPopin.bekreftRepresentasjonTekst" },
        { 0: valgtMalgruppe.tittel.toLowerCase() },
      );

  return (
    <div className={cls("Grid-12-12 u--marginTop3", className)}>
      <Element name="bekreftRepresentasjon" />
      <Tooltip
        className="u--block"
        message={fields.bekreftRepresentasjon.message}
        align="left"
        position="bottom"
        disabled={!fields.bekreftRepresentasjon.error}
        alwaysShow
      >
        <Checkbox
          label={label}
          onChange={onChangeWrapped}
          checked={fields.bekreftRepresentasjon.value}
          error={fields.bekreftRepresentasjon.error}
        />
      </Tooltip>
    </div>
  );
};

export default BekreftRepresentasjon;
