import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Popin, usePopin } from "@utdanningsdirektoratet/popin";
import { FormattedMessage } from "react-intl";

const IngenMerknaderManueltRegistrert = ({
  kanHaIngenMerknader,
  onClick,
  harIngenMerknader,
}: {
  onClick: (harIngenMerknader: boolean) => Promise<void>;
  kanHaIngenMerknader: boolean;
  harIngenMerknader?: boolean;
}) => {
  const [popinId, show, close] = usePopin();

  if (!kanHaIngenMerknader) return null;

  return (
    <div className="Page-gray">
      <div className="Page-section Page-section--narrow">
        <Checkbox
          label={
            <FormattedMessage id="slettMerknaderPopin.ingenMerknaderButtonText" />
          }
          onChange={(e) => (e.target.checked ? show() : onClick(false))}
          checked={harIngenMerknader}
        />
      </div>
      <Popin
        id={popinId}
        closeableByExternalEvents
        onSave={() => {
          onClick(true);
          close();
        }}
        saveButton={<FormattedMessage id="formLabels.ja" />}
        cancelButton={<FormattedMessage id="formLabels.avbryt" />}
      >
        <FormattedMessage id="slettMerknaderPopin.ingenMerknaderAdvarsel" />
      </Popin>
    </div>
  );
};

export default IngenMerknaderManueltRegistrert;
