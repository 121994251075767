import {
  SeksjonUttalelseSubmitModel,
  SeksjonUttalelseViewModel,
} from "../ApiClients";
import { isSeksjonBesvart } from "../logic/validation";

export default (
  seksjonUttalelser: Array<SeksjonUttalelseViewModel> = [],
  mapAll = false,
): Array<SeksjonUttalelseSubmitModel> =>
  seksjonUttalelser
    .filter((u) => mapAll || isSeksjonBesvart(u))
    .map<SeksjonUttalelseSubmitModel>(
      (u) =>
        ({
          id: u.seksjonId,
        }) satisfies SeksjonUttalelseSubmitModel,
    );
