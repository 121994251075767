import { Values } from "@utdanningsdirektoratet/form";
import mapSporsmalUttalelserVm from "./mapSporsmalUttalelserVm";
import mapSeksjonUttalelserVm from "./mapSeksjonUttalelserVm";
import mapNotatUttalelserVm from "./mapNotatUttalelserVm";
import type {
  HøringUttalelseSubmitModelV2,
  InnsenderViewModel,
  Målform,
  UttalelseViewModel,
} from "../ApiClients";
import type { FieldType } from "../pages/uttalelse/components/sendInn/SendInnPopin";

export default (
  eksternId: string,
  uttalelse: UttalelseViewModel | undefined,
  ingenMerknader: boolean,
  userMalform: Målform,
  mapAll: boolean,
  values?: Values<FieldType>,
): HøringUttalelseSubmitModelV2 => {
  const sporsmalUttalelser = mapSporsmalUttalelserVm(
    uttalelse?.spørsmål,
    mapAll,
  );
  const seksjonUttalelser = mapSeksjonUttalelserVm(
    uttalelse?.seksjoner,
    mapAll,
  );
  const notatUttalelser = mapNotatUttalelserVm(uttalelse?.notater, mapAll);

  const vm: HøringUttalelseSubmitModelV2 = {
    eksternId,
    ingenMerknader,
    sporsmalUttalelser,
    seksjonUttalelser,
    notatUttalelser,
    userMålform: userMalform,
  };

  if (values) {
    return {
      ...vm,
      innsender: {
        navn: values.navn,
        epost: values.epost,
        malgruppe: +values.malgruppe,
        representerer: values.representerer,
        stilling: values.stilling,
        fylke: values.fylke,
        kommune: values.kommune,
        undertype: Object.keys(values.undertype)
          .filter((key) => values.undertype[key])
          .map((key) => +key),
      } satisfies InnsenderViewModel,
    } satisfies HøringUttalelseSubmitModelV2;
  }

  return vm;
};
