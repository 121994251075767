import { getArray, getString } from "./vmUtils";
import {
  EgendefinertSvarModel,
  EnigUenig,
  JaNei,
  PositivNegativHoldning,
  SpørsmålUttalelseSubmitModel,
  SpørsmålUttalelseViewModel,
} from "../ApiClients";
import { isSporsmalBesvart } from "../logic/validation";

export default (
  sporsmalUttalelser: Array<SpørsmålUttalelseViewModel> = [],
  mapAll = false,
): Array<SpørsmålUttalelseSubmitModel> =>
  sporsmalUttalelser
    .filter((u) => mapAll || isSporsmalBesvart(u))
    .map<SpørsmålUttalelseSubmitModel>(
      (u) =>
        ({
          id: u.spørsmålId,
          positivNegativHoldning:
            u.positivNegativHoldning !== PositivNegativHoldning.IkkeAngitt
              ? u.positivNegativHoldning
              : undefined,
          jaNei: u.jaNei !== JaNei.IkkeAngitt ? u.jaNei : undefined,
          enigUenig:
            u.enigUenig !== EnigUenig.IkkeAngitt ? u.enigUenig : undefined,
          kommentar: getString(u.kommentar),
          kortTekst: getString(u.kortTekst),
          heltall: getString(u.heltall),
          dato: getString(u.dato),
          fylkeKommune: getArray(u.fylkeKommune),
          svarPåSvarAlternativer: getArray(
            u.svarPåSvarAlternativer?.map((spsa) => spsa.svarAlternativId),
          ),
          egendefinertSvar: getArray(
            u.svarPåSvarAlternativer
              ?.filter((spsa) => spsa.egendefinertSvar?.trim())
              .map(
                (spsa) =>
                  ({
                    svarAlternativId: spsa.svarAlternativId,
                    egendefinertSvar: spsa.egendefinertSvar,
                  }) as EgendefinertSvarModel,
              ),
          ),
        }) satisfies SpørsmålUttalelseSubmitModel,
    );
