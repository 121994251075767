import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Input } from "@utdanningsdirektoratet/input";
import { useIntl } from "react-intl";
import { isEmpty } from "@utdanningsdirektoratet/utils/string";
import Obligatorisk from "./Obligatorisk";
import { getTextWithLocale } from "../../../../../utils/localeUtils";
import { useLocale } from "../../../../../hooks";
import { SvarAlternativViewModel } from "../../../../../ApiClients";

const SvarAlternativ = ({
  id,
  alternativ,
  selected,
  egendefinertSvar,
  svarAlternativ,
  error,
  disabled,
  obligatorisk,
  type,
  message,
  onChange,
}: {
  id: number;
  selected: boolean;
  egendefinertSvar: string;
  disabled: boolean;
  svarAlternativ: Array<SvarAlternativViewModel>;
  alternativ: SvarAlternativViewModel;
  obligatorisk: boolean;
  message: string;
  onChange: (selected: boolean, egendefinert: string) => void;
  type: "checkbox" | "radio";
  error: boolean;
}) => {
  const intl = useIntl();
  const hasErrors = error && !disabled;
  const altError = error && selected && isEmpty(egendefinertSvar);
  const locale = useLocale();

  const check =
    type === "checkbox" ? (
      <Checkbox
        checked={selected}
        name={`checkbox-alternativ-${id}`}
        label={
          alternativ.erEgendefinert
            ? intl.formatMessage({ id: "formLabels.annet" })
            : getTextWithLocale(alternativ.tittel, locale)
        }
        disabled={disabled}
        error={hasErrors}
        onChange={() => onChange(!selected, egendefinertSvar)}
        className="SvarAlternativ-checkbox"
      />
    ) : (
      <Radio
        checked={selected}
        name={`radio-alternativ-${id}`}
        label={
          alternativ.erEgendefinert
            ? intl.formatMessage({ id: "formLabels.annet" })
            : getTextWithLocale(alternativ.tittel, locale)
        }
        disabled={disabled}
        error={hasErrors}
        onChange={() => onChange(!selected, egendefinertSvar)}
        className="SvarAlternativ-radio"
      />
    );

  const svarAlternativClass = cls({
    SvarAlternativ: true,
    "SvarAlternativ--obligatory": obligatorisk && svarAlternativ.length === 1,
  });

  const svarAlternativInputClass = cls({
    "SvarAlternativ-input": true,
    "SvarAlternativ-input--egendefinert": alternativ.erEgendefinert,
  });

  return (
    <div className={svarAlternativClass}>
      <div className={svarAlternativInputClass}>{check}</div>
      {alternativ.erEgendefinert ? (
        <Input
          aria-label={intl.formatMessage({ id: "formLabels.annetTekst" })}
          disabled={disabled}
          value={egendefinertSvar}
          className="SvarAlternativ-wrapper SvarAlternativ-wrapper-input--egendefinert"
          onFocus={() => onChange(true, egendefinertSvar)}
          onChange={(e) => onChange(selected, e.target.value)}
          error={altError}
          message={message}
        />
      ) : null}
      {svarAlternativ.length === 1 ? (
        <Obligatorisk isObligatorisk={obligatorisk}></Obligatorisk>
      ) : null}
    </div>
  );
};

export default SvarAlternativ;
