import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { MenuPanel } from "@utdanningsdirektoratet/layout";
import { usePopin } from "@utdanningsdirektoratet/popin";
import { FormattedMessage, useIntl } from "react-intl";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import UtkastPopin from "./components/utkastPopin/UtkastPopin";
import HoeringsUttalelse from "./HoeringsUttalelse";
import { Header } from "./components/header/header";
import {
  institusjonNavnLang,
  logoUrl,
  version,
} from "../../logic/initialState";
import { useVersion } from "../../hooks";
import TutorialOverlay from "./components/TutorialOverlay";

const Uttalelse = () => {
  const [popinId] = usePopin();
  const intl = useIntl();
  const [currentVersion, setCurrentVersion] = useState(version);
  const { data: newVersion = version } = useVersion();

  useEffect(() => {
    if (currentVersion !== newVersion) {
      newNotification(
        "info",
        intl.formatMessage({ id: "default.nyVersjon" }),
        intl.formatMessage({ id: "default.nyVersjonMessage" }),
      );
      setCurrentVersion(newVersion);
    }
  }, [currentVersion, intl, newVersion]);

  return (
    <ErrorBoundary
      fallback={
        <>
          <MenuPanel title={institusjonNavnLang} external></MenuPanel>
          <div className="Grid">
            <div className="Page">
              <header>
                <div className="Header">
                  <div className="Grid-12-12">
                    <img
                      src={logoUrl}
                      alt={institusjonNavnLang}
                      className="Header-logo"
                    />
                  </div>
                </div>
              </header>
              <main id="main">
                <h1>
                  <div className="Page-section">
                    <h1 className="Page-title u-pageTitle">
                      <FormattedMessage id="error.detharOppstattEnFeil" />
                    </h1>
                  </div>
                </h1>
              </main>
            </div>
          </div>
        </>
      }
    >
      <Suspense
        fallback={
          <>
            <MenuPanel title={institusjonNavnLang} external></MenuPanel>
            <div className="Grid">
              <div className="Page">
                <header>
                  <div className="Header">
                    <div className="Grid-12-12">
                      <img
                        src={logoUrl}
                        alt={institusjonNavnLang}
                        className="Header-logo"
                      />
                    </div>
                  </div>
                </header>
                <main id="main">
                  <div className="Page-section">
                    <Placeholder height={3} />
                    <Placeholder height={1} width={50} />
                    <Placeholder height={1} width={30} />
                    <Placeholder height={1} width={40} />
                    <Placeholder height={1} width={70} />
                  </div>
                </main>
              </div>
            </div>
          </>
        }
      >
        <Header />
        <HoeringsUttalelse utkastPopinId={popinId} />
        <TutorialOverlay />
      </Suspense>
      <UtkastPopin popinId={popinId} />
    </ErrorBoundary>
  );
};

export default Uttalelse;
