import { useState, useEffect } from "react";
import { Select } from "@utdanningsdirektoratet/select";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import { FormValidation } from "@utdanningsdirektoratet/form";
import type { FieldType } from "../SendInnPopin";
import { MalgruppeViewModel } from "../../../../../ApiClients";

const fieldsToReset = [
  "representerer",
  "stilling",
  "fylke",
  "kommune",
  "undertype",
  "bekreftRepresentasjon",
] as Array<keyof FieldType>;

const Malgruppe = ({
  fields,
  malgrupper,
  alleMalgrupper,
  resetFields,
}: {
  malgrupper: Record<string, Array<MalgruppeViewModel>>;
  alleMalgrupper: Array<MalgruppeViewModel>;
  fields: FormValidation<FieldType>["fields"];
  resetFields: FormValidation<FieldType>["resetFields"];
}) => {
  const [localSektor, setLocalSektor] = useState("");
  const intl = useIntl();
  useEffect(() => {
    if (fields.sektor.value) setLocalSektor(fields.sektor.value);
  }, [fields.sektor.value]);

  const findMalgruppe = (malgruppe: [string, Array<MalgruppeViewModel>]) =>
    malgruppe[1].find((mg) => mg.sektor === localSektor) || malgruppe[1][0];

  const options = Object.entries(malgrupper)
    .sort((a, b) => findMalgruppe(a).rekkefølge - findMalgruppe(b).rekkefølge)
    .map((m) => ({
      value: findMalgruppe(m).id,
      text: m[0],
    }));

  const withDefault = [
    {
      value: "",
      text: `— ${intl.formatMessage({ id: "sendInnPopin.velg" })} —`,
    },
    ...options,
  ];

  const onChangeWrapped = (e: React.ChangeEvent<HTMLSelectElement>) => {
    resetFields(fieldsToReset);
    fields.malgruppe.onChange(e);
    const valgtMalgruppe = alleMalgrupper.find(
      (m) => m.id === parseInt(e.target.value, 10),
    );
    if (valgtMalgruppe && malgrupper[valgtMalgruppe.tittel].length === 1) {
      fields.sektor.onChange(valgtMalgruppe.sektor);
      setLocalSektor(valgtMalgruppe.sektor);
    } else if (valgtMalgruppe) {
      fields.sektor.onChange(undefined);
    }
  };

  return (
    <div className="SendInnPopin-flexInput">
      <Element name="malgruppe" />
      <Select
        {...fields.malgruppe}
        id="velgMalgruppe"
        className="SendInnPopin-select"
        onChange={onChangeWrapped}
        options={withDefault}
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgMalgruppe" })}
      />
    </div>
  );
};

export default Malgruppe;
