import {
  HøringsNotatUttalelseSubmitModel,
  HøringViewModel,
  SpørsmålUttalelseSubmitModel,
} from "../ApiClients";

export const getNotat = (notatId: number, hoering: HøringViewModel) => {
  return (
    hoering.notater.find((n) => n.id === notatId) ??
    hoering.notater.sort((a, b) => a.rekkefølge - b.rekkefølge)[0]
  );
};

export const getNextNotat = (notatId: number, hoering: HøringViewModel) => {
  const notat = getNotat(notatId, hoering);
  const index = hoering.notater.indexOf(notat);
  if (index >= 0 && index < hoering.notater.length - 1) {
    return hoering.notater.sort((a, b) => a.rekkefølge - b.rekkefølge)[
      index + 1
    ];
  }
  return notat;
};

export type NotatSvarMulighetTypes = keyof HøringsNotatUttalelseSubmitModel;
export const notatPrefix = "notat";
export type NotatFormId =
  `${typeof notatPrefix}-${string}-${NotatSvarMulighetTypes}`;
export const getNotatFormId = (
  notatId: number | string,
  type: NotatSvarMulighetTypes,
): NotatFormId => `${notatPrefix}-${notatId}-${type}`;

export type SporsmalSvarMulighetTypes =
  | keyof SpørsmålUttalelseSubmitModel
  | "vedlegg";
export const sporsmalPrefix = "sporsmal";
export type SpørsmålFormId =
  `${typeof sporsmalPrefix}-${string}-${SporsmalSvarMulighetTypes}`;
export const getSpørsmålFormId = (
  spørsmålId: number | string,
  type: SporsmalSvarMulighetTypes,
): SpørsmålFormId => `${sporsmalPrefix}-${spørsmålId}-${type}`;
