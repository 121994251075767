/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import { Footer } from "@utdanningsdirektoratet/layout";
import {
  CookieBanner,
  MatomoCookieVendor,
  NecessaryCookie,
  SentryCookieVendor,
  StatisticsCookie,
} from "@utdanningsdirektoratet/cookiebanner";
import { FormattedMessage, useIntl } from "react-intl";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";
import { CookieVendor } from "@utdanningsdirektoratet/cookiebanner/utils";
import { useSearchParams } from "react-router-dom";
import { footer, matomoId, personvernerklaering } from "../logic/initialState";
import { useLocale } from "../hooks";

const HoeringsFooter = ({
  hoeringId,
}: {
  hoeringId?: string | number | null;
}) => {
  const intl = useIntl();
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [searchParams] = useSearchParams();
  const locale = useLocale();

  searchParams.set("maalform", locale);
  if (hoeringId) searchParams.set("hoeringId", `${hoeringId}`);

  const cookiesLink = (
    <a
      href={personvernerklaering?.url ?? `/Cookies?${searchParams.toString()}`}
      target={personvernerklaering?.blank ? "_blank" : undefined}
      rel={personvernerklaering?.blank ? "noopener noreferrer" : undefined}
    >
      <FormattedMessage id="footer.personvernerklaering" />
    </a>
  );

  const links = [
    ...(footer ?? []).map((f) => (
      <a
        href={f.url}
        target={f.blank ? "_blank" : undefined}
        rel={f.blank ? "noopener noreferrer" : undefined}
      >
        {f.tekst}
      </a>
    )),
    cookiesLink,
    <div
      tabIndex={0}
      role="link"
      onClick={() => setShowCookieBanner(true)}
      onKeyDown={onKeyDown(() => setShowCookieBanner(true))}
    >
      <FormattedMessage id="footer.cookiesInnstillinger" />
    </div>,
  ];

  return (
    <div className="Footer">
      <Footer items={links} />
      <CookieBanner
        necessaryCookie={NecessaryCookie([SentryCookieVendor])}
        title={intl.formatMessage({ id: "cookies.cookiesSamtykke" })}
        cookies={[
          {
            ...StatisticsCookie([
              ...([matomoId ? MatomoCookieVendor : null].filter(
                Boolean,
              ) as Array<CookieVendor>),
            ]),
            description: StatisticsCookie([]).description?.replace(
              "Utdanningsdirektoratet ",
              "",
            ),
          },
        ]}
        show={showCookieBanner}
        onAccept={() => setShowCookieBanner(false)}
      />
    </div>
  );
};

export default HoeringsFooter;
