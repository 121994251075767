import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { useTriggerPdfCreation } from "@utdanningsdirektoratet/hooks/useTriggerPdfCreation";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import DocumentMetadata from "../../components/DocumentMetadata";
import InnsendtHeader from "./components/hoeringsUttalelse/InnsendtHeader";
import {
  getDefaultLocaleFromMalforms,
  getTextWithLocale,
} from "../../utils/localeUtils";
import SeksjonUttalelse from "./components/uttalelse/SeksjonUttalelse";
import NotatUttalelse from "./components/uttalelse/NotatUttalelse";
import Signatur from "./components/hoeringsUttalelse/Signatur";
import Fotnoter from "./components/hoeringsUttalelse/Fotnoter";
import {
  useFylker,
  useHoering,
  useInnsendingsKode,
  useLocale,
  useUttalelse,
} from "../../hooks";
import Vedlegg from "./components/hoeringsUttalelse/Vedlegg";
import { routes } from "../../routes";
import Header from "./components/hoeringsUttalelse/Header";
import { features } from "../../logic/initialState";
import { useContextState } from "../../StateProvider";

const Pdf = ({
  type = "høring",
}: {
  type?: "høring" | "utkast" | "uttalelse";
}) => {
  const intl = useIntl();
  const locale = useLocale();
  const { data: uttalelse } = useUttalelse();
  const { data: hoering } = useHoering();
  const { data: fylker } = useFylker();
  const innsendingskode = useInnsendingsKode();
  const [, dispatch] = useContextState();

  useTriggerPdfCreation(
    window.initialState.consoleLogTriggerMessage,
    uttalelse,
    hoering,
    fylker,
  );

  useEffect(() => {
    // Set locale if default doesnt exist in høring
    if (!hoering.målforms.includes(locale)) {
      dispatch({
        type: "SET_LOCALE",
        payload: getDefaultLocaleFromMalforms(hoering.målforms),
      });
    }
  }, [dispatch, hoering.målforms, locale]);

  useEffect(() => {
    // Set locale if default doesnt match usermålform
    if (uttalelse && uttalelse.innsendt && uttalelse.userMålform !== locale) {
      dispatch({
        type: "SET_LOCALE",
        payload: uttalelse.userMålform,
      });
    }
  }, [dispatch, locale, uttalelse]);

  const hasFeedback = uttalelse?.notater.some((hnu) => hnu.hasFeedback);

  const currentNotats = hoering.notater
    .filter(
      (hn) =>
        type === "høring" ||
        uttalelse?.ingenMerknader ||
        !hasFeedback ||
        uttalelse?.notater.find((hnu) => hnu.notatId === hn.id)?.hasFeedback,
    )
    .sort((a, b) => a.rekkefølge - b.rekkefølge);

  const { innsendt = type === "uttalelse" } = uttalelse ?? {};

  const gridClass = cls({
    Grid: true,
    "Grid--innsendt": innsendt,
  });

  const pageClass = cls({
    Page: true,
    "Page--readonly": true,
    "Page--innsendt": innsendt,
    "Page--utkast": type === "utkast",
  });

  const headerClass = cls({
    PageBreak: type !== "høring",
  });

  if (currentNotats.length === 0) return null;

  const valgtMalgruppe = hoering.målgrupper.find(
    (m) => m.id === uttalelse?.malgruppeId,
  );

  const bekreftRepresentasjon = getTextWithLocale(
    valgtMalgruppe?.bekreftRepresentasjonTekst,
    locale,
  )
    ? getTextWithLocale(valgtMalgruppe?.bekreftRepresentasjonTekst, locale)
    : intl.formatMessage(
        { id: "sendInnPopin.bekreftRepresentasjonTekst" },
        { 0: valgtMalgruppe?.tittel.toLowerCase() },
      );

  return (
    <ErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <DocumentMetadata title={getTextWithLocale(hoering.tittel, locale)} />
        <div className={gridClass}>
          <div className={pageClass}>
            <header className={headerClass}>
              <Header />
              <h1 className="Page-title u-pageTitle">
                {getTextWithLocale(hoering.tittel, locale)}
              </h1>
              <InnsendtHeader
                show={type !== "høring"}
                innsenderStilling={uttalelse?.innsenderStilling}
                referanse={
                  innsendt && features[hoering.mode].enSakPerSvarInnstilling
                    ? uttalelse?.arkivnummer
                    : null
                }
              />
              {type === "utkast" && (
                <p>
                  <strong>
                    <FormattedMessage id="uttalelse.klikkHerForÅRedigereOgSendeInn" />
                  </strong>
                  <br />
                  <a
                    href={
                      routes.utkast(hoering.id, uttalelse!.eksternId) +
                      (innsendingskode
                        ? `?innsendingsKode=${innsendingskode}`
                        : "")
                    }
                  >
                    {window.location.origin +
                      routes.utkast(hoering.id, uttalelse!.eksternId) +
                      (innsendingskode
                        ? `?innsendingsKode=${innsendingskode}`
                        : "")}
                  </a>
                </p>
              )}
              {uttalelse?.bekreftelsesUri && (
                <p>
                  <strong>
                    <FormattedMessage id="uttalelse.klikkHerForÅBekrefte" />
                  </strong>
                  <br />
                  <a href={uttalelse.bekreftelsesUri}>
                    {uttalelse.bekreftelsesUri}
                  </a>
                </p>
              )}
              {valgtMalgruppe?.bekreftRepresentasjon && (
                <p>
                  <Icon icon="check" placement="before" />
                  {bekreftRepresentasjon}
                </p>
              )}
              {uttalelse?.ingenMerknader && (
                <p>
                  <FormattedMessage id="uttalelse.instansenHarIngenMerknader" />
                </p>
              )}
            </header>
            <main id="main">
              {currentNotats.map((currentNotat) => {
                return (
                  <div className="PageBreak" key={currentNotat.id}>
                    {currentNotats.length > 1 &&
                      getTextWithLocale(currentNotat.tittel, locale) !==
                        getTextWithLocale(hoering.tittel, locale) && (
                        <div className="Page-section">
                          <h1 className="Page-title u-pageTitle">
                            {getTextWithLocale(currentNotat.tittel, locale)}
                          </h1>
                        </div>
                      )}
                    {currentNotat.saksBehandlereTekst && (
                      <div className="Header-content Grid-xl-6-12 Grid-12-12">
                        <span>
                          <div className="Header-title">
                            <FormattedMessage id="header.varSaksbehandler" />
                          </div>
                          {currentNotat.saksBehandlereTekst}
                        </span>
                      </div>
                    )}
                    <SeksjonUttalelse
                      readonly
                      editManueltRegistert={false}
                      seksjons={currentNotat.seksjoner}
                      ingenMerknader={uttalelse?.ingenMerknader ?? false}
                      skjulNummerering={currentNotat.skjulNummerering}
                      forhandsvisning={
                        type === "høring" && hoering.forhåndsvisnings
                      }
                      autosave={() => Promise.resolve("")}
                    />
                    <br />
                    <NotatUttalelse
                      readonly
                      editManueltRegistert={false}
                      notat={currentNotat}
                      ingenMerknader={uttalelse?.ingenMerknader ?? false}
                      forhandsvisning={
                        type === "høring" && hoering.forhåndsvisnings
                      }
                    />
                    <Signatur signatur={currentNotat.signaturSeksjon} />
                    <Fotnoter
                      fotnoter={currentNotat.fotnoter}
                      notatId={currentNotat.id}
                    />
                    {type === "høring" && (
                      <Vedlegg
                        vedlegg={hoering.vedlegg}
                        notatVedlegg={currentNotat.vedlegg}
                      />
                    )}
                  </div>
                );
              })}
            </main>
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Pdf;
