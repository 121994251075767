import { getString } from "./vmUtils";
import {
  HøringsNotatUttalelseSubmitModel,
  HøringsNotatUttalelseViewModel,
  PositivNegativHoldning,
} from "../ApiClients";
import { isNotatBesvart } from "../logic/validation";

export default (
  notatUttalelser: Array<HøringsNotatUttalelseViewModel> = [],
  mapAll = false,
): Array<HøringsNotatUttalelseSubmitModel> =>
  notatUttalelser
    .filter((u) => mapAll || isNotatBesvart(u))
    .map<HøringsNotatUttalelseSubmitModel>(
      (u) =>
        ({
          id: u.notatId,
          generellHoldning:
            u.generellHoldning !== PositivNegativHoldning.IkkeAngitt
              ? u.generellHoldning
              : undefined,
          generellKommentar: getString(u.generellKommentar),
        }) satisfies HøringsNotatUttalelseSubmitModel,
    );
