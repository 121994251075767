import { FormValidation } from "@utdanningsdirektoratet/form";
import Undertype from "./Undertype";
import Representerer from "./Representerer";
import MinStilling from "./MinStilling";
import FylkeKommune from "./FylkeKommune";
import type { FieldType } from "../SendInnPopin";
import { MalgruppeViewModel } from "../../../../../ApiClients";

const InnsenderInfo = ({
  fields,
  resetFields,
  malgrupper,
}: {
  malgrupper: Array<MalgruppeViewModel>;
  fields: FormValidation<FieldType>["fields"];
  resetFields: FormValidation<FieldType>["resetFields"];
}) => {
  const malgruppe = fields.malgruppe.value;

  if (!malgruppe) return null;

  const valgtMalgruppe = malgrupper.find((m) => m.id === +malgruppe)!;

  return (
    <div>
      <Representerer fields={fields} valgtMalgruppe={valgtMalgruppe} />
      <FylkeKommune
        fields={fields}
        valgtMalgruppe={valgtMalgruppe}
        resetFields={resetFields}
      />
      <Undertype fields={fields} valgtMalgruppe={valgtMalgruppe} />
      <MinStilling fields={fields} valgtMalgruppe={valgtMalgruppe} />
    </div>
  );
};

export default InnsenderInfo;
