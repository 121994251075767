import { close, Popin } from "@utdanningsdirektoratet/popin";
import { Input } from "@utdanningsdirektoratet/input";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useFormValidation,
  validationFunctions,
} from "@utdanningsdirektoratet/form";

import { HoeringClient } from "../../../ApiClients";
import { epostValidation } from "../../../logic/validation";
import { useUttalelse } from "../../../hooks";

const client = new HoeringClient();

const RedigerEpostPopin = ({
  id,
  eksternId,
  epost,
}: {
  id: string;
  eksternId: string;
  epost: string;
}) => {
  const intl = useIntl();
  const { mutate } = useUttalelse();

  const { fields, values, isValid } = useFormValidation<{
    epost: string;
  }>({
    epost: {
      value: epost,
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({ id: "validation.epostErObligatorisk" }),
        },
        epostValidation(intl),
      ],
    },
  });

  const editEmail = async () => {
    await client.endreEpost(eksternId, values.epost, null);
    await mutate();
    close(id);
  };

  return (
    <Popin
      id={id}
      title={<FormattedMessage id="innsendtUttalelse.endreEpostAdresse" />}
      onSave={() => {
        if (isValid) {
          editEmail();
        }
      }}
      saveButton={<FormattedMessage id="innsendtUttalelse.lagreOgSend" />}
      cancelButton={<FormattedMessage id="formLabels.avbryt" />}
    >
      <Input
        {...fields.epost}
        className="u--marginBottom2"
        type="email"
        label={intl.formatMessage({ id: "innsendtUttalelse.epostadresse" })}
      />
    </Popin>
  );
};

export default RedigerEpostPopin;
