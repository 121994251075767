import { Button } from "@utdanningsdirektoratet/button";
import { FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { HoeringClient } from "../../../../ApiClients";
import mapVm from "../../../../vm/mapVm";
import { useHoering, useLocale, useUttalelse } from "../../../../hooks";
import { emptyGuid, useContextState } from "../../../../StateProvider";
import { mapFormToUttalelse } from "../../../../vm/vmUtils";

const client = new HoeringClient();

const LagreOgGaTilbake = ({
  manuellRegistreringsKode,
  returnTo,
}: {
  manuellRegistreringsKode: string;
  returnTo: string;
}) => {
  const { data: hoering } = useHoering();
  const locale = useLocale();
  const { data: uttalelse } = useUttalelse();
  const { values } = useForm();
  const [{ ingenMerknader }] = useContextState();
  const lagreOgGaTilbake = async () => {
    show();
    const vm = mapVm(
      uttalelse!.eksternId,
      mapFormToUttalelse(uttalelse!, values),
      ingenMerknader,
      locale,
      true,
    );
    await client.lagreManuellRegistrering(
      hoering.id,
      uttalelse?.eksternId ?? emptyGuid,
      manuellRegistreringsKode,
      vm,
    );
    hide();
    window.location.href = returnTo;
  };

  return (
    <div className="Page-section Page-section--narrow FooterButtons">
      <div className="Grid-xl-8-12 Grid-md-6-12 Grid-12-12">
        <Button
          className="Button--colored FooterButtons-button FooterButtons-submit"
          onClick={() => lagreOgGaTilbake()}
        >
          <FormattedMessage id="formLabels.lagreOgGaTilbake" />
        </Button>
      </div>
    </div>
  );
};

export default LagreOgGaTilbake;
