import { animateScroll, scroller } from "react-scroll";

export const ScrollType = {
  NOTAT: "notat",
  SEKSJON: "seksjon",
  SPORSMAL: "sporsmal",
};

export const scrollToId = (type: string, id: string | number) =>
  `${type}-${id}`;

const defaultOptions = {
  smooth: true,
  duration: 250,
};

export const scrollToTop = (options?: Record<string, string>) => {
  animateScroll.scrollToTop({ ...defaultOptions, ...options });
};

export const scrollToBottom = (options?: Record<string, string>) => {
  animateScroll.scrollToBottom({ ...defaultOptions, ...options });
};

// I options er det en property som heter containerId, denne kan settes hvis en annen scroll enn body skal scrolles.
// Med andre ord om det er en scrollpane inne i en annen scrollpane kan denne verdien endre hvilken av disse to pane'sa som blir targetet.
export const scrollTo = (
  name: string,
  options?: Record<string, string | number>,
) => scroller.scrollTo(name, { ...defaultOptions, ...options });

export const scrollToHeader = () => {
  scrollTo("headerScroll", {
    offset: -40,
  });
};
