import * as React from "react";
import { Link } from "@utdanningsdirektoratet/link";
import styles from "./Innholdsfortegnelse.module.less";
import { scrollTo } from "../../../../utils/scrollUtils";
import { getTextWithLocale } from "../../../../utils/localeUtils";
import { Målform, SeksjonViewModel } from "../../../../ApiClients";
import { useLocale } from "../../../../hooks";

const GetNiva = (
  seksjoner: Array<SeksjonViewModel>,
  skjulNummerering = false,
  locale: Målform,
  prevLevel = 0,
): React.ReactNode => {
  const current = seksjoner[0];
  if (!current) return null;

  const nextLevel = seksjoner.findIndex((s) => s.nivå !== current.nivå);
  const tittel = getTextWithLocale(current.tittel, locale);

  if (current.nivå > prevLevel) {
    return (
      <>
        <ol>
          <li className={styles.innholdsfortegnelseItem}>
            <Link
              href={`#seksjon-${current.id}`}
              onClick={() => scrollTo(`seksjon-${current.id}`)}
            >
              {`${skjulNummerering ? tittel : `${current.nummerering} ${tittel}`}`}
            </Link>
          </li>
          {GetNiva(
            seksjoner.slice(1, nextLevel === -1 ? undefined : nextLevel),
            skjulNummerering,
            locale,
            current.nivå,
          )}
        </ol>
        {nextLevel !== -1 &&
          GetNiva(
            seksjoner.slice(nextLevel),
            skjulNummerering,
            locale,
            seksjoner[nextLevel - 1].nivå,
          )}
      </>
    );
  }

  return (
    <>
      <li className={styles.innholdsfortegnelseItem}>
        <Link
          onClick={() => scrollTo(`seksjon-${current.id}`)}
          href={`#seksjon-${current.id}`}
        >
          {`${skjulNummerering ? tittel : `${current.nummerering} ${tittel}`}`}
        </Link>
      </li>
      {GetNiva(seksjoner.slice(1), skjulNummerering, locale, current.nivå)}
    </>
  );
};

const Innholdsfortegnelse = ({
  seksjoner = [],
  skjulNummerering = false,
}: {
  seksjoner: Array<SeksjonViewModel>;
  skjulNummerering?: boolean;
}) => {
  const locale = useLocale();
  if (seksjoner.length === 0) return null;

  return (
    <ol className={styles.innholdsfortegnelse}>
      {GetNiva(
        seksjoner.filter((s) => s.nivå <= 1),
        skjulNummerering,
        locale,
      )}
    </ol>
  );
};

export default Innholdsfortegnelse;
