import { useState, useEffect, useMemo } from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Input } from "@utdanningsdirektoratet/input";
import { Icon } from "@utdanningsdirektoratet/icon";
import { PopinFooter } from "@utdanningsdirektoratet/popin";
import { Fields, Form, FormValidation } from "@utdanningsdirektoratet/form";
import { Radio, RadioGroup } from "@utdanningsdirektoratet/radio";
import { useIntl, FormattedMessage } from "react-intl";
import { Element } from "react-scroll";
import BekreftRepresentasjon from "./BekreftRepresentasjon";
import MalgruppeSektor from "./MalgruppeSektor";
import { type FieldType } from "../SendInnPopin";
import {
  GeografiObligatoriskhet,
  MalgruppeViewModel,
  Obligatoriskhet,
  Sektor,
} from "../../../../../ApiClients";
import { scrollTo } from "../../../../../utils/scrollUtils";
import {
  features,
  personvernerklaering,
} from "../../../../../logic/initialState";
import { useHoering } from "../../../../../hooks";

const fieldsToReset = [
  "sektor",
  "malgruppe",
  "representerer",
  "stilling",
  "fylke",
  "kommune",
  "undertype",
  "bekreftRepresentasjon",
] as Array<keyof FieldType>;

const getHasMultipleMalgrupperOrNeedsMoreMetaData = (
  valgtMalgruppe: MalgruppeViewModel | undefined,
  malgruppeEnkeltperson: Array<MalgruppeViewModel>,
  malgruppeOffentligEllerPrivat: Array<MalgruppeViewModel>,
) => {
  if (!valgtMalgruppe) return true;
  if (valgtMalgruppe.sektor === Sektor.Enkeltperson) {
    return !(
      valgtMalgruppe.innsenderRepresentererObligatoriskhet ===
        Obligatoriskhet.IkkeEtterspør &&
      valgtMalgruppe.innsenderStillingObligatoriskhet ===
        Obligatoriskhet.IkkeEtterspør &&
      valgtMalgruppe.geografiObligatoriskhet ===
        GeografiObligatoriskhet.IkkeEtterspør &&
      malgruppeEnkeltperson.length === 1
    );
  }
  return !(
    valgtMalgruppe.innsenderRepresentererObligatoriskhet ===
      Obligatoriskhet.IkkeEtterspør &&
    valgtMalgruppe.innsenderStillingObligatoriskhet ===
      Obligatoriskhet.IkkeEtterspør &&
    valgtMalgruppe.geografiObligatoriskhet ===
      GeografiObligatoriskhet.IkkeEtterspør &&
    malgruppeOffentligEllerPrivat.length === 1
  );
};

const SendInnForm = ({
  fields,
  popinId,
  onSuccess,
  resetFields,
  validateFields,
  representererEnkeltperson,
  setRepresenterer,
  ...rest
}: {
  fields: Fields<FieldType>;
  popinId: string;
  onSuccess: () => void;
  representererEnkeltperson: boolean | undefined;
  setRepresenterer: React.Dispatch<React.SetStateAction<boolean | undefined>>;
} & FormValidation<FieldType>) => {
  const [onSubmitClicked, setOnSubmitClicked] = useState(false);
  const intl = useIntl();
  const { data: hoering} = useHoering();
  const { lenkeTilPersonvernerklaering, bekrefteSvar } = features[hoering.mode];
  const { målgrupper } = hoering;

  useEffect(() => {
    if (målgrupper.length === 1) {
      fields.sektor.onChange(målgrupper[0].sektor);
      fields.malgruppe.onChange(målgrupper[0].id);
    }
    if ([...new Set(målgrupper.map((m) => m.tittel))].length === 1) {
      fields.malgruppe.onChange(målgrupper[0].id);
    }
    if ([...new Set(målgrupper.map((m) => m.sektor))].length === 1) {
      fields.sektor.onChange(målgrupper[0].sektor);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const valgtMalgruppe = useMemo(
    () => målgrupper.find((m) => m.id === +fields.malgruppe.value),
    [fields.malgruppe.value, målgrupper],
  );
  const malgruppeEnkeltperson = useMemo(
    () =>
      målgrupper.filter(
        (malgruppe) => malgruppe.sektor === Sektor.Enkeltperson,
      ),
    [målgrupper],
  );
  const malgruppeOffentligEllerPrivat = useMemo(
    () =>
      målgrupper.filter(
        (malgruppe) => malgruppe.sektor !== Sektor.Enkeltperson,
      ),
    [målgrupper],
  );

  const buttonClass = cls({
    "u--marginTop2":
      bekrefteSvar || (valgtMalgruppe && valgtMalgruppe.autoPubliser),
    "u--marginTop5":
      !bekrefteSvar && !(valgtMalgruppe && valgtMalgruppe.autoPubliser),
  });

  const hasMultipleMalgrupperOrNeedsMoreMetaData = useMemo(
    () =>
      getHasMultipleMalgrupperOrNeedsMoreMetaData(
        valgtMalgruppe,
        malgruppeEnkeltperson,
        malgruppeOffentligEllerPrivat,
      ),
    [malgruppeEnkeltperson, malgruppeOffentligEllerPrivat, valgtMalgruppe],
  );

  const onValidationError = () => {
    if (fields.navn.error) {
      scrollTo("navn", { popinId });
    } else if (fields.epost.error) {
      scrollTo("epost", { popinId });
    } else if (fields.sektor.error) {
      scrollTo("sektor", { popinId });
    } else if (fields.malgruppe.error) {
      scrollTo("malgruppe", { popinId });
    } else if (fields.representerer.error) {
      scrollTo("representerer", { popinId });
    } else if (fields.fylke.error) {
      scrollTo("fylke", { popinId });
    } else if (fields.kommune.error) {
      scrollTo("kommune", { popinId });
    } else if (fields.undertype.error) {
      scrollTo("undertype", { popinId });
    } else if (fields.stilling.error) {
      scrollTo("stilling", { popinId });
    } else if (fields.bekreftRepresentasjon.error) {
      scrollTo("bekreftRepresentasjon", { popinId });
    }
    setOnSubmitClicked(false);
  };

  return (
    <Form
      {...rest}
      fields={fields}
      resetFields={resetFields}
      onSubmit={onSuccess}
      onError={onValidationError}
      validateFields={validateFields}
    >
      {({ onSubmit }) => {
        const onSubmitClick = () => {
          setOnSubmitClicked(true);
          onSubmit();
        };
        return (
          <>
            <div>
              <div className="Grid-12-12">
                <Element name="navn" />
                <label className="SendInnPopin-label" htmlFor="mittNavn">
                  {intl.formatMessage({ id: "sendInnPopin.mittNavn" })}
                </label>
                <Input
                  aria-label={intl.formatMessage({
                    id: "sendInnPopin.mittNavn",
                  })}
                  {...fields.navn}
                  placeholder=""
                  id="mittNavn"
                  className="SendInnPopin-input"
                />
              </div>

              <Element name="epost" />
              <div className="Grid-12-12">
                <label className="SendInnPopin-label" htmlFor="minEpost">
                  {intl.formatMessage({ id: "sendInnPopin.minEpost" })}
                </label>
                <Input
                  aria-label={intl.formatMessage({
                    id: "sendInnPopin.minEpost",
                  })}
                  {...fields.epost}
                  placeholder=""
                  id="minEpost"
                  className="SendInnPopin-input"
                  autoComplete="email"
                  type="email"
                />
              </div>
              {malgruppeEnkeltperson.length > 0 &&
              malgruppeOffentligEllerPrivat.length > 0 ? (
                <>
                  <div className="SendInnPopin-label">
                    <FormattedMessage id="sendInnPopin.hvemSender" />
                  </div>
                  <Element name="sektor" />
                  <RadioGroup
                    error={fields.sektor.error}
                    className="SendInnPopin-radioWrapper"
                    label="Sektor valg"
                    name="group"
                  >
                    <Radio
                      error={fields.sektor.error}
                      boxed
                      checked={
                        representererEnkeltperson !== undefined &&
                        representererEnkeltperson
                      }
                      label={intl.formatMessage(
                        { id: "sendInnPopin.jegSenderPaaVegneAv" },
                        {
                          JegRepresenterer: intl.formatMessage({
                            id: "sendInnPopin.megSelv",
                          }),
                        },
                      )}
                      className="SendInnPopin-radiobuton"
                      onChange={() => {
                        if (
                          representererEnkeltperson === undefined ||
                          !representererEnkeltperson
                        ) {
                          resetFields(fieldsToReset);
                          setRepresenterer(true);
                          fields.sektor.onChange(Sektor.Enkeltperson);
                          if (malgruppeEnkeltperson.length === 1) {
                            fields.malgruppe.onChange(
                              malgruppeEnkeltperson[0].id,
                            );
                          }
                        }
                      }}
                    >
                      {hasMultipleMalgrupperOrNeedsMoreMetaData ? (
                        <MalgruppeSektor
                          fields={fields}
                          valgtMalgruppe={valgtMalgruppe}
                          malgrupper={målgrupper}
                          resetFields={resetFields}
                        />
                      ) : null}
                    </Radio>
                    <Radio
                      error={fields.sektor.error}
                      boxed
                      checked={
                        representererEnkeltperson !== undefined &&
                        !representererEnkeltperson
                      }
                      label={intl.formatMessage(
                        { id: "sendInnPopin.jegSenderPaaVegneAv" },
                        {
                          JegRepresenterer: intl.formatMessage({
                            id: "sendInnPopin.minArbeidsgiver",
                          }),
                        },
                      )}
                      onChange={() => {
                        if (
                          representererEnkeltperson === undefined ||
                          representererEnkeltperson
                        ) {
                          setRepresenterer(false);
                          resetFields(fieldsToReset);
                          if (
                            [
                              ...new Set(
                                malgruppeOffentligEllerPrivat.map(
                                  (m) => m.sektor,
                                ),
                              ),
                            ].length === 1
                          ) {
                            fields.sektor.onChange(
                              malgruppeOffentligEllerPrivat[0].sektor,
                            );
                          }
                          if (
                            [
                              ...new Set(
                                malgruppeOffentligEllerPrivat.map(
                                  (m) => m.tittel,
                                ),
                              ),
                            ].length === 1
                          ) {
                            fields.malgruppe.onChange(
                              malgruppeOffentligEllerPrivat[0].id,
                            );
                          }
                          if (malgruppeOffentligEllerPrivat.length === 1) {
                            fields.malgruppe.onChange(
                              malgruppeOffentligEllerPrivat[0].id,
                            );
                          }
                        }
                      }}
                    >
                      <Element name="sektor" />
                      {hasMultipleMalgrupperOrNeedsMoreMetaData ? (
                        <MalgruppeSektor
                          fields={fields}
                          valgtMalgruppe={valgtMalgruppe}
                          malgrupper={målgrupper}
                          resetFields={resetFields}
                        />
                      ) : null}
                    </Radio>
                  </RadioGroup>
                </>
              ) : (
                <MalgruppeSektor
                  fields={fields}
                  valgtMalgruppe={valgtMalgruppe}
                  malgrupper={målgrupper}
                  resetFields={resetFields}
                />
              )}

              {valgtMalgruppe && fields.sektor.value ? (
                <BekreftRepresentasjon
                  className={
                    malgruppeEnkeltperson.length > 0 &&
                    malgruppeOffentligEllerPrivat.length > 0
                      ? undefined
                      : "u--marginTop1"
                  }
                  fields={fields}
                  valgtMalgruppe={valgtMalgruppe}
                />
              ) : null}

              <div>
                {(!hoering.skjult &&
                  valgtMalgruppe &&
                  valgtMalgruppe.autoPubliser) ||
                (lenkeTilPersonvernerklaering && personvernerklaering) ? (
                  <div className="SendInnPopin-message">
                    <Icon icon="circle info" type="small" placement="before" />
                    {!hoering.skjult &&
                    valgtMalgruppe &&
                    valgtMalgruppe.autoPubliser ? (
                      <>
                        <FormattedMessage id="sendInnPopin.automatiskPublisering" />
                        <br />
                      </>
                    ) : null}
                    {lenkeTilPersonvernerklaering && personvernerklaering ? (
                      <>
                        <FormattedMessage id="sendInnPopin.lenkeTilPersonvernErklaeringText" />
                        <br />
                        <a
                          href={personvernerklaering.url}
                          target={
                            personvernerklaering.blank ? "_blank" : undefined
                          }
                          rel={
                            personvernerklaering.blank
                              ? "noopener noreferrer"
                              : undefined
                          }
                        >
                          {personvernerklaering.tekst}
                        </a>
                      </>
                    ) : null}
                  </div>
                ) : null}

                {bekrefteSvar ? (
                  <div className="SendInnPopin-message">
                    <Icon icon="email" type="small" placement="before" />
                    <FormattedMessage id="sendInnPopin.duVilMottaBekreftelsesEpost" />
                  </div>
                ) : null}
              </div>
            </div>
            <PopinFooter
              className={buttonClass}
              popinId={popinId}
              onSave={onSubmitClick}
              saveButtonProps={{
                disabled: onSubmitClicked,
              }}
              cancelButton={<FormattedMessage id="formLabels.avbryt" />}
              saveButton={<FormattedMessage id="formLabels.sendInn" />}
            />
          </>
        );
      }}
    </Form>
  );
};

export default SendInnForm;
