import { close, Popin } from "@utdanningsdirektoratet/popin";
import {
  DetailedList,
  DetailedListRow,
  DetailedListCell,
} from "@utdanningsdirektoratet/detailedlist";
import { FormattedMessage } from "react-intl";
import { compareDesc } from "date-fns/compareDesc";
import { parseDate, formatDate } from "@utdanningsdirektoratet/utils/date";
import { Link } from "react-router-dom";
import { getUtkasts } from "../../../../utils/localStorageUtils";
import { UTKAST_FORMAT } from "../../../../constants/dateFormats";
import { routes } from "../../../../routes";
import { useHoeringId } from "../../../../hooks";

const UtkastPopin = ({ popinId }: { popinId: string }) => {
  const hoeringId = useHoeringId();
  const utkasts = getUtkasts(hoeringId);

  return (
    <Popin
      id={popinId}
      title={<FormattedMessage id="utkastPopin.title" />}
      disableInitialFocus
      cancelButton={<FormattedMessage id="utkastPopin.nyttUtkast" />}
    >
      <DetailedList>
        {utkasts
          .sort((a, b) =>
            compareDesc(parseDate(a.endret!), parseDate(b.endret!)),
          )
          .map((utkast) => (
            <DetailedListRow key={utkast.eksternId} color="grey">
              {({ className }) => {
                return (
                  <Link
                    to={routes.utkast(hoeringId, utkast.eksternId!)}
                    onClick={() => {
                      close(popinId);
                    }}
                    className={className}
                  >
                    <DetailedListCell>
                      <FormattedMessage id="utkastPopin.utkastSistLagret" />
                    </DetailedListCell>
                    <DetailedListCell size="220px" right>
                      {formatDate(utkast.endret!, UTKAST_FORMAT)}
                    </DetailedListCell>
                  </Link>
                );
              }}
            </DetailedListRow>
          ))}
      </DetailedList>
    </Popin>
  );
};

export default UtkastPopin;
