import {
  HøringsNotatUttalelseViewModel,
  SpørsmålUttalelseViewModel,
  UttalelseViewModel,
} from "../ApiClients";
import {
  getNotatFormId,
  getSpørsmålFormId,
  NotatFormId,
  notatPrefix,
  NotatSvarMulighetTypes,
  sporsmalPrefix,
  SporsmalSvarMulighetTypes,
  SpørsmålFormId,
} from "../utils/hoeringUtils";

export const getArray = <T>(array: Array<T> | null | undefined) => {
  return (array ?? []).length > 0 ? array : undefined;
};

export const getString = <T>(obj: T | null | undefined) => {
  return (obj ?? "")?.toString().trim().length > 0 ? obj : undefined;
};

export const mapFormToUttalelse = (
  uttalelse: UttalelseViewModel,
  values: Record<SpørsmålFormId | NotatFormId, unknown>,
) => {
  return {
    ...uttalelse,
    notater: Object.values(
      (Object.keys(values) as Array<NotatFormId>)
        .map(
          (key) =>
            key.split("-") as [
              typeof notatPrefix,
              string,
              NotatSvarMulighetTypes,
            ],
        )
        .filter((keys) => keys[0] === notatPrefix)
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr[1]]: {
              ...(acc[curr[1]] ??
                ({
                  notatId: +curr[1],
                } as HøringsNotatUttalelseViewModel)),
              [curr[2]]: values[getNotatFormId(curr[1], curr[2])],
            },
          }),
          {} as Record<string, HøringsNotatUttalelseViewModel>,
        ),
    ) satisfies Array<HøringsNotatUttalelseViewModel>,
    spørsmål: Object.values(
      (Object.keys(values) as Array<SpørsmålFormId>)
        .map(
          (key) =>
            key.split("-") as [
              typeof sporsmalPrefix,
              string,
              SporsmalSvarMulighetTypes,
            ],
        )
        .filter((keys) => keys[0] === sporsmalPrefix)
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr[1]]: {
              ...(acc[curr[1]] ??
                ({ spørsmålId: +curr[1] } as SpørsmålUttalelseViewModel)),
              [curr[2]]: values[getSpørsmålFormId(curr[1], curr[2])],
            },
          }),
          {} as Record<string, SpørsmålUttalelseViewModel>,
        ),
    ) satisfies Array<SpørsmålUttalelseViewModel>,
  } satisfies UttalelseViewModel;
};
