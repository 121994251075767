import { FormValidation } from "@utdanningsdirektoratet/form";
import { Input } from "@utdanningsdirektoratet/input";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import { MalgruppeViewModel, Obligatoriskhet } from "../../../../../ApiClients";

import type { FieldType } from "../SendInnPopin";

const Representerer = ({
  fields,
  valgtMalgruppe,
}: {
  fields: FormValidation<FieldType>["fields"];
  valgtMalgruppe: MalgruppeViewModel;
}) => {
  const intl = useIntl();
  const visRepresenterer =
    valgtMalgruppe.innsenderRepresentererObligatoriskhet ===
      Obligatoriskhet.Obligatorisk ||
    valgtMalgruppe.innsenderRepresentererObligatoriskhet ===
      Obligatoriskhet.Valgfritt;

  if (!visRepresenterer) return null;

  const representererLabel =
    valgtMalgruppe.innsenderRepresentererObligatoriskhet !==
    Obligatoriskhet.Obligatorisk
      ? `${intl.formatMessage({ id: "sendInnPopin.valgfritt" })}`
      : undefined;

  const label = valgtMalgruppe.innsenderRepresentererEtikett
    ? valgtMalgruppe.innsenderRepresentererEtikett
    : intl.formatMessage(
        { id: "sendInnPopin.navnPa" },
        { 0: valgtMalgruppe.tittel.toLowerCase() },
      );

  return (
    <div className="Grid-12-12">
      <Element name="representerer" />
      <label className="SendInnPopin-label" htmlFor="representerer">
        {label}
      </label>
      <Input
        {...fields.representerer}
        id="representerer"
        className="SendInnPopin-input"
        placeholder={representererLabel}
        aria-label={label}
      />
    </div>
  );
};

export default Representerer;
