import { useEffect, useMemo, useState } from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Input } from "@utdanningsdirektoratet/input";
import {
  AccountInput,
  OrganisasjonsnummerInput,
} from "@utdanningsdirektoratet/maskedinput";
import { useIntl, FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { useForm } from "@utdanningsdirektoratet/form";
import { MaskedInputProps } from "@utdanningsdirektoratet/maskedinput/MaskedInput";
import Obligatorisk from "./Obligatorisk";
import {
  KortSvarTypes,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import { useUttalelse } from "../../../../../hooks";

const KortTekstSvar = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
  type,
}: {
  id: number;
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
  type: KortSvarTypes;
}) => {
  const { data: uttalelse } = useUttalelse();
  const [search] = useSearchParams();
  const intl = useIntl();

  const { fields = {} } =
    useForm<Record<SpørsmålFormId, SpørsmålUttalelseViewModel["kortTekst"]>>();

  const field = useMemo(
    () =>
      fields[getSpørsmålFormId(id, "kortTekst")] ?? {
        value: uttalelse?.spørsmål.find((s) => s.spørsmålId === id)?.kortTekst,
        onChange: () => null,
      },
    [fields, id, uttalelse?.spørsmål],
  );

  const [value, setValue] = useState(field.value ?? "");

  useEffect(() => {
    if (search.get("orgnr") && type === KortSvarTypes.Orgnr) {
      field.onChange(search.get("orgnr") ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value === field.value) return;
    field.onChange(value);
  }, [field, value]);

  const kortTextClass = cls({
    "Sporsmal-input": true,
    "Sporsmal-korttekst": true,
    "Sporsmal-korttekst--wide": field.value && field.value.length >= 42,
    "Sporsmal-korttekst--narrow": type !== KortSvarTypes.Tekst,
  });

  const oblicatoriskClass = cls({
    "Obligatorisk--block": field.value && field.value.length >= 42,
  });

  if (!forhandsvisning && readonly) {
    if (field.value) {
      return <div className="Sporsmal-body">{field.value}</div>;
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittKortSvar" />
      </div>
    );
  }

  const inputProps = {
    ...field,
    className: kortTextClass,
    disabled: readonly,
    icon: "warning",
    placeholder: intl.formatMessage({ id: "formLabels.skrivHer" }),
  } satisfies React.ComponentProps<typeof Input> &
    Omit<MaskedInputProps, "mask">;

  let input;
  switch (type) {
    case KortSvarTypes.Kontonr:
      input = (
        <AccountInput
          {...inputProps}
          onChange={setValue}
          aria-label="Kontonummer"
          placeholder="Skriv kontonummer..."
        />
      );
      break;
    case KortSvarTypes.Orgnr:
      input = (
        <OrganisasjonsnummerInput
          {...inputProps}
          onChange={setValue}
          aria-label="Organisasjonsnummer"
          placeholder="Skriv organisasjonsnummer..."
        />
      );
      break;
    case KortSvarTypes.Epost:
      input = (
        <Input
          {...inputProps}
          onChange={(e) => setValue(e.target.value)}
          aria-label="E-post"
          placeholder="Skriv e-post..."
        />
      );
      break;
    case KortSvarTypes.Tekst:
    default:
      input = (
        <Input
          {...inputProps}
          onChange={(e) => setValue(e.target.value)}
          maxLength={4000}
          aria-label="Tekst"
          placeholder="Skriv her..."
        />
      );
      break;
  }

  return (
    <div className="Sporsmal-body">
      <Obligatorisk className={oblicatoriskClass} isObligatorisk={obligatorisk}>
        {input}
      </Obligatorisk>
    </div>
  );
};

export default KortTekstSvar;
