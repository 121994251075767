import {
  Form,
  useFormValidation,
  validationFunctions,
} from "@utdanningsdirektoratet/form";
import { Input } from "@utdanningsdirektoratet/input";
import { TagsInput, TagType } from "@utdanningsdirektoratet/tagsinput";
import { Textarea } from "@utdanningsdirektoratet/textarea";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Popin, PopinFooter, close } from "@utdanningsdirektoratet/popin";
import { FormattedMessage, useIntl } from "react-intl";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { useInnsendingsKode, useUttalelse } from "../../../../hooks";
import { epostValidation } from "../../../../logic/validation";
import { HoeringClient, Målform } from "../../../../ApiClients";
import { emptyGuid } from "../../../../StateProvider";

type Tilgang = "LESETILGANG" | "SKRIVETILGANG";

const client = new HoeringClient();

const VideresendUtkastPopin = ({ id }: { id: string }) => {
  const intl = useIntl();
  const { data: uttalelse } = useUttalelse();
  const innsendingskode = useInnsendingsKode();

  const formValidation = useFormValidation<{
    innsenderEpost: string;
    mottakerEpost: Array<TagType>;
    kommentar: string;
    tilgang: Tilgang;
  }>({
    innsenderEpost: {
      value: "",
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({ id: "validation.epostErObligatorisk" }),
        },
        epostValidation(intl),
      ],
    },
    mottakerEpost: {
      value: [],
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({
            id: "validation.manglendeMottagereposter",
          }),
        },
      ],
    },
    kommentar: { value: "" },
    tilgang: {
      value: "LESETILGANG",
      validation: [validationFunctions.required],
    },
  });
  const { fields, values } = formValidation;

  const videresendUtkast = async () => {
    show();
    const result = await client.delUtkast(
      innsendingskode,
      uttalelse?.eksternId ?? emptyGuid,
      {
        ...values,
        eksternId: uttalelse?.eksternId ?? emptyGuid,
        mottakerEpost: values.mottakerEpost.map((m) => m.title),
        skrivetilgang: values.tilgang === "SKRIVETILGANG",
        userMålform:
          uttalelse?.userMålform === Målform.Nynorsk
            ? Målform.Nynorsk
            : Målform.Bokmål,
      },
    );
    close(id);
    newNotification("success", result.title!, result.message!, true);
    hide();
  };

  return (
    <Popin
      id={id}
      title={<FormattedMessage id="videresendPopin.title" />}
      closeableByExternalEvents
      cancelButton={false}
    >
      <Form {...formValidation} onSubmit={videresendUtkast}>
        {({ onSubmit }) => {
          return (
            <div>
              <p>
                <FormattedMessage id="videresendPopin.body" />
              </p>
              <Input
                {...fields.innsenderEpost}
                type="text"
                label={
                  <FormattedMessage id="videresendPopin.delUtkastInnsenderEpost" />
                }
              />
              <div className="u--marginTop1">
                <TagsInput
                  {...fields.mottakerEpost}
                  delimiters={["Enter", " ", "Tab"]}
                  validationRegex={window.epostRegex}
                  label={
                    <FormattedMessage id="videresendPopin.delUtkastMottagernesEposter" />
                  }
                  tagmessage={
                    <FormattedMessage id="validation.maVaereGyldigEpostadresse" />
                  }
                  addOnBlur
                />
              </div>
              <div className="u--marginTop3">
                <Textarea
                  label={
                    <FormattedMessage id="videresendPopin.delUtkastKommentar" />
                  }
                  {...fields.kommentar}
                />
              </div>
              <div className="u--marginTop3">
                <Radio
                  label={
                    <FormattedMessage id="videresendPopin.delUtkastLeseTilgang" />
                  }
                  name="videresendtUtkast"
                  checked={fields.tilgang.value === "LESETILGANG"}
                  onChange={() => fields.tilgang.onChange("LESETILGANG")}
                  error={fields.tilgang.error}
                />
                <Radio
                  label={
                    <FormattedMessage id="videresendPopin.delUtkastSkriveTilgang" />
                  }
                  className="u--marginTop1"
                  name="videresendtUtkast"
                  checked={fields.tilgang.value === "SKRIVETILGANG"}
                  onChange={() => fields.tilgang.onChange("SKRIVETILGANG")}
                  error={fields.tilgang.error}
                />
              </div>
              <PopinFooter
                popinId={id}
                onSave={onSubmit}
                cancelButton={<FormattedMessage id="formLabels.avbryt" />}
                saveButton={<FormattedMessage id="formLabels.videresend" />}
              />
            </div>
          );
        }}
      </Form>
    </Popin>
  );
};

export default VideresendUtkastPopin;
