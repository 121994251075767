import { useEffect, useMemo, useState } from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { useIntl, FormattedMessage } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Button } from "@utdanningsdirektoratet/button";
import {
  RichText,
  RichTextView,
  ConfigBuilder,
  RichTextProps,
} from "@utdanningsdirektoratet/richtext";
import { TrashableRichText } from "@utdanningsdirektoratet/richtext/TrashableRichText";
import { useToggle } from "@utdanningsdirektoratet/hooks";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { useForm } from "@utdanningsdirektoratet/form";
import { froala } from "../../../../../logic/initialState";
import {
  getNotatFormId,
  getSpørsmålFormId,
  NotatFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import {
  HøringsNotatUttalelseViewModel,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import { useUttalelse } from "../../../../../hooks";

const Kommentar = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
  generell,
  maxAntallTegn,
}: {
  id: number;
  readonly: boolean;
  obligatorisk?: boolean;
  forhandsvisning: boolean;
  maxAntallTegn?: number | null;
  generell?: boolean;
}) => {
  const { data: uttalelse } = useUttalelse();
  const intl = useIntl();
  const { fields = {} } =
    useForm<
      Record<
        NotatFormId | SpørsmålFormId,
        | HøringsNotatUttalelseViewModel["generellKommentar"]
        | SpørsmålUttalelseViewModel["kommentar"]
      >
    >();

  const field = useMemo(
    () =>
      fields[
        generell
          ? getNotatFormId(id, "generellKommentar")
          : getSpørsmålFormId(id, "kommentar")
      ] ?? {
        value: generell
          ? uttalelse?.notater.find((n) => n.notatId === id)?.generellKommentar
          : uttalelse?.spørsmål.find((s) => s.spørsmålId === id)?.kommentar,
        onChange: () => null,
      },
    [fields, generell, id, uttalelse?.notater, uttalelse?.spørsmål],
  );

  const config = useMemo(() => {
    return new ConfigBuilder()
      .withToolbar((toolbar) => {
        return toolbar.filter(
          (x) =>
            x !== "underline" && x !== "paragraphFormat" && x !== "insertTable",
        );
      })
      .withPlugins((plugins) => {
        return maxAntallTegn ? [...plugins, "charCounter"] : plugins;
      })
      .build();
  }, [maxAntallTegn]);

  const richTextOptions = {
    heightMin: 150,
    pasteDeniedTags: ["table", "thead", "tbody", "th", "tr", "td", "caption"],
    ...(maxAntallTegn ? { charCounterMax: maxAntallTegn } : {}),
  };

  let buttonText = "formLabels.leggTilKommentar";
  let label = "formLabels.kommentar";
  let ingenKommentar = "validation.ingenKommentar";
  if (generell) {
    buttonText = "formLabels.leggTilGenerellKommentar";
    label = "formLabels.generellKommentar";
    ingenKommentar = "validation.ingenGenerellKommentar";
  }

  const [value, setValue] = useState(field.value ?? "");
  const [show, toggle, autoFocus] = useToggle(!!field.value || obligatorisk);

  useEffect(() => {
    if (value === field.value) return;
    field.onChange(value);
  }, [field, value]);

  useEffect(() => {
    if (
      ((!!field.value || obligatorisk) && !show) ||
      (!(!!field.value || obligatorisk) && show)
    )
      toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, obligatorisk]);

  const onTrash = () => {
    setValue("");
    toggle();
  };

  if (!forhandsvisning && readonly) {
    if (!field.value) {
      return (
        <div className="Sporsmal-body Sporsmal-body--italic">
          <FormattedMessage id={ingenKommentar} />
        </div>
      );
    }
    return (
      <div className="Sporsmal-body">
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          className="RichText-label"
        >
          <FormattedMessage id={label} />
        </label>
        <RichTextView html={field.value} />
      </div>
    );
  }

  const buttonClass = cls({
    "Sporsmal-button": true,
    "Sporsmal-button--error": field.error,
  });

  const richtextProps = {
    disabled: readonly,
    value,
    onChange: setValue,
    onBlur: (e) => setValue(e ?? ""),
    error: field.error,
    license: froala,
    icon: "warning",
    message: field.message,
    "aria-label": intl.formatMessage({ id: label }),
    autoFocus,
    config,
    lazy: !!field.value || obligatorisk,
    options: richTextOptions,
  } satisfies RichTextProps;

  if (obligatorisk) {
    return (
      <div className="Sporsmal-body">
        <RichText {...richtextProps} />
      </div>
    );
  }

  return (
    <div className="Sporsmal-body">
      {show ? (
        <TrashableRichText
          {...richtextProps}
          label={intl.formatMessage({ id: label })}
          onTrash={onTrash}
          trashTooltip={intl.formatMessage({ id: "formLabels.slettKommentar" })}
        />
      ) : (
        <Tooltip
          className="u--block"
          message={field.message}
          position="top"
          align="left"
          disabled={!field.error}
          alwaysShow
        >
          <Button className={buttonClass} onClick={toggle} disabled={readonly}>
            <Icon icon="speech bubble" type="small" placement="before" />
            <FormattedMessage id={buttonText} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default Kommentar;
