/* eslint-disable @typescript-eslint/no-explicit-any */

export const flatten = (object: any): any => {
  return Object.keys(object).reduce((curr, key) => {
    if (!Object.prototype.hasOwnProperty.call(object, key)) return curr;
    if (typeof object[key] === "object" && object[key] !== null) {
      const flat = flatten(object[key]);
      return {
        ...curr,
        ...Object.keys(flat).reduce((innerCurr, innerKey) => {
          if (!Object.prototype.hasOwnProperty.call(flat, innerKey)) {
            return innerCurr;
          }
          return { ...innerCurr, [`${key}.${innerKey}`]: flat[innerKey] };
        }, {}),
      };
    }
    return { ...curr, [key]: object[key] };
  }, {});
};
