import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "@utdanningsdirektoratet/input";
import { Radio } from "@utdanningsdirektoratet/radio";
import {
  Form,
  useFormValidation,
  validationFunctions,
} from "@utdanningsdirektoratet/form";
import { Popin, PopinFooter, close } from "@utdanningsdirektoratet/popin";
import { downloadFile } from "@utdanningsdirektoratet/utils/download";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import { HoeringClient } from "../../../../ApiClients";
import { urls } from "../../../../logic/initialState";
import { epostValidation } from "../../../../logic/validation";
import { useInnsendingsKode, useUttalelse } from "../../../../hooks";

type RadioTypes = "EPOST" | "LAST_NED";
const client = new HoeringClient();

const LagreUtkastPopin = ({ id, ...rest }: { id: string }) => {
  const { data: uttalelse } = useUttalelse();
  const innsendingsKode = useInnsendingsKode();
  const intl = useIntl();

  const formValidation = useFormValidation<{
    innsenderEpost: string;
    type: RadioTypes;
  }>({
    innsenderEpost: {
      value: "",
      validation: [
        {
          ...validationFunctions.requiredIf(
            validationFunctions.required,
            (_, values) => (values as { type: RadioTypes }).type === "EPOST",
          ),
          message: intl.formatMessage({ id: "validation.epostErObligatorisk" }),
        },
        {
          ...validationFunctions.requiredIf(
            epostValidation(intl),
            (_, values) => (values as { type: RadioTypes }).type === "EPOST",
          ),
          message: epostValidation(intl).message,
        },
      ],
    },
    type: {
      value: "EPOST",
      validation: [validationFunctions.required],
    },
  });
  const { fields, values } = formValidation;

  if (!uttalelse) return null;

  const { eksternId } = uttalelse;
  const onSuccess = async () => {
    if (values.type === "EPOST") {
      show();
      const result = await client.lagreUtkast(
        eksternId,
        innsendingsKode,
        values.innsenderEpost,
      );
      newNotification(
        "success",
        result.title!,
        result.message ?? undefined,
        true,
      );
      hide();
      close(id);
    } else if (values.type === "LAST_NED") {
      downloadFile(`${urls.lastNedUtkastUrl}/${eksternId}`, "get", {
        innsendingsKode: innsendingsKode as string,
      });
    }
  };

  return (
    <Popin
      className="LagreUtkastPopin"
      id={id}
      title={<FormattedMessage id="lagreUtkastPopin.title" />}
      closeableByExternalEvents
      cancelButton={false}
    >
      <Form {...rest} {...formValidation} onSubmit={onSuccess}>
        {({ onSubmit }) => {
          return (
            <div>
              <p>
                <FormattedMessage id="lagreUtkastPopin.body" />
              </p>
              <Radio
                label={
                  <FormattedMessage id="lagreUtkastPopin.saveDraftSendEpost" />
                }
                name="lagreUtkast"
                checked={fields.type.value === "EPOST"}
                onChange={() => fields.type.onChange("EPOST")}
                error={fields.type.error}
              >
                <p>
                  <FormattedMessage id="lagreUtkastPopin.saveDraftLastNedText" />
                </p>
                <Input
                  {...fields.innsenderEpost}
                  type="email"
                  autoComplete="email"
                  label={<FormattedMessage id="lagreUtkastPopin.epost" />}
                />
              </Radio>
              <Radio
                label={
                  <FormattedMessage id="lagreUtkastPopin.saveDraftLastNed" />
                }
                name="lagreUtkast"
                checked={fields.type.value === "LAST_NED"}
                onChange={() => fields.type.onChange("LAST_NED")}
                error={fields.type.error}
                boxed
              />
              <PopinFooter
                popinId={id}
                onSave={onSubmit}
                saveButton={<FormattedMessage id="formLabels.lagre" />}
                cancelButton={<FormattedMessage id="formLabels.avbryt" />}
              />
            </div>
          );
        }}
      </Form>
    </Popin>
  );
};

export default LagreUtkastPopin;
