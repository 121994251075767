import { Element } from "react-scroll";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import { FormattedMessage } from "react-intl";
import PositivNegativSvar from "./components/PositivNegativ";
import JaNeiSvar from "./components/JaNeiSvar";
import EnigUenigSvar from "./components/EnigUenigSvar";
import EttValg from "./components/EttValg";
import FlereValg from "./components/FlereValg";
import HeltallSvar from "./components/HeltallSvar";
import KortTekstSvar from "./components/KortTekstSvar";
import DatoSvar from "./components/DatoSvar";
import FylkeKommuneSvar from "./components/FylkeKommuneSvar";
import Kommentar from "./components/Kommentar";
import Vedlegg from "./components/Vedlegg";
import { SpørsmålViewModel } from "../../../../ApiClients";
import { useLocale, useUttalelse } from "../../../../hooks";
import { getTextWithLocale } from "../../../../utils/localeUtils";
import { scrollToId, ScrollType } from "../../../../utils/scrollUtils";
import { isSporsmalBesvart } from "../../../../logic/validation";

const SporsmalUttalelse = ({
  sporsmal,
  ingenMerknader,
  forhandsvisning,
  editManueltRegistert,
  autosave,
  ...rest
}: {
  sporsmal: SpørsmålViewModel;
  ingenMerknader: boolean;
  readonly: boolean;
  forhandsvisning: boolean;
  editManueltRegistert: boolean;
  autosave: (immediate?: boolean) => Promise<string>;
}) => {
  const {
    tittel,
    positivNegativ,
    jaNei,
    enigUenig,
    svarAlternativer,
    heltall,
    kortTekst,
    dato,
    fylkeKommune,
    kommentar,
    vedlegg,
    id,
  } = sporsmal;
  const locale = useLocale();

  const { data } = useUttalelse();
  const uttalelse = data?.spørsmål?.find((s) => s.spørsmålId === sporsmal.id);

  const tittelRiktigMalform = getTextWithLocale(tittel, locale);

  const tittelFormatted = tittelRiktigMalform
    ? tittelRiktigMalform.replace(/\n/g, "")
    : "";
  const noAnswers =
    rest.readonly &&
    !forhandsvisning &&
    !isSporsmalBesvart(uttalelse) &&
    !editManueltRegistert;
  const readonly = rest.readonly && (!editManueltRegistert || ingenMerknader);

  return (
    <div className="Sporsmal" id={`sporsmal_${id}`}>
      <Element name={scrollToId(ScrollType.SPORSMAL, id)} />
      {tittelFormatted ? (
        <div className="Sporsmal-heading">
          <div className="Sporsmal-heading-title">{tittelFormatted}</div>
          {sporsmal.brødTekst ? (
            <RichTextView
              html={getTextWithLocale(sporsmal.brødTekst, locale)}
            />
          ) : null}
        </div>
      ) : null}

      {noAnswers ? (
        <div>
          <div className="Sporsmal-body Sporsmal-body--italic">
            <FormattedMessage id="validation.ikkeSvart" />
          </div>
        </div>
      ) : (
        <div>
          {positivNegativ.canHave ? (
            <PositivNegativSvar
              id={id}
              obligatorisk={positivNegativ.isObligatorisk}
              readonly={readonly}
              forhandsvisning={forhandsvisning}
            />
          ) : null}
          {jaNei.canHave ? (
            <JaNeiSvar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={jaNei.isObligatorisk}
              id={id}
            />
          ) : null}
          {enigUenig.canHave ? (
            <EnigUenigSvar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={enigUenig.isObligatorisk}
              id={id}
            />
          ) : null}
          {svarAlternativer.canHave && !svarAlternativer.flereValg ? (
            <EttValg
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              ettValg={svarAlternativer}
              obligatorisk={svarAlternativer.isObligatorisk}
              id={id}
            />
          ) : null}
          {svarAlternativer.canHave && svarAlternativer.flereValg ? (
            <FlereValg
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              flereValg={svarAlternativer}
              obligatorisk={svarAlternativer.isObligatorisk}
              id={id}
            />
          ) : null}
          {heltall.canHave ? (
            <HeltallSvar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={heltall.isObligatorisk}
              id={id}
            />
          ) : null}
          {kortTekst.canHave ? (
            <KortTekstSvar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={kortTekst.isObligatorisk}
              type={kortTekst.type}
              id={id}
            />
          ) : null}
          {dato.canHave ? (
            <DatoSvar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={dato.isObligatorisk}
              id={id}
            />
          ) : null}
          {fylkeKommune.canHave ? (
            <FylkeKommuneSvar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              fylkeKommune={fylkeKommune}
              id={id}
            />
          ) : null}
          {kommentar.canHave ? (
            <Kommentar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={kommentar.isObligatorisk}
              id={id}
              maxAntallTegn={kommentar.maxAntallTegn}
            />
          ) : null}
          {vedlegg.canHave ? (
            <Vedlegg
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              obligatorisk={vedlegg.isObligatorisk}
              id={id}
              editManueltRegistert={editManueltRegistert}
              autosave={autosave}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SporsmalUttalelse;
