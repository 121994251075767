import { Button } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Popin, show, close, usePopin } from "@utdanningsdirektoratet/popin";
import { FormattedMessage, useIntl } from "react-intl";

const IngenMerknader = ({
  kanHaIngenMerknader,
  onClick,
  hasFeedback,
  readonly,
  harIngenMerknader,
}: {
  onClick: () => void;
  kanHaIngenMerknader: boolean;
  readonly: boolean;
  hasFeedback?: boolean;
  harIngenMerknader?: boolean;
}) => {
  const intl = useIntl();
  const [popinId] = usePopin();
  if (!kanHaIngenMerknader || (readonly && !harIngenMerknader)) return null;

  const onClickWrapped = () => {
    if (hasFeedback) {
      show(popinId);
    } else {
      onClick();
    }
  };

  const confirmOnClick = () => {
    close(popinId);
    onClick();
  };

  if (readonly) {
    return (
      <div className="Page-gray">
        <div className="Page-section Page-section--narrow">
          <strong>
            <FormattedMessage id="slettMerknaderPopin.ingenMerknaderButtonText" />
          </strong>
        </div>
      </div>
    );
  }

  return (
    <div className="Page-gray">
      <div className="Page-section Page-section--narrow">
        <Button onClick={onClickWrapped} disabled={readonly}>
          <FormattedMessage id="slettMerknaderPopin.ingenMerknaderButtonText" />
        </Button>
      </div>
      <Popin
        id={popinId}
        title={intl.formatMessage({ id: "slettMerknaderPopin.title" })}
        closeableByExternalEvents
        saveButton={
          <>
            <Icon icon="trash" placement="before" />
            <FormattedMessage id="slettMerknaderPopin.jaSlett" />
          </>
        }
        onSave={confirmOnClick}
        cancelButton={<FormattedMessage id="formLabels.avbryt" />}
      >
        <FormattedMessage id="slettMerknaderPopin.body" />
      </Popin>
    </div>
  );
};

export default IngenMerknader;
