import { Button, ButtonGroup } from "@utdanningsdirektoratet/button";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import Obligatorisk from "./Obligatorisk";
import {
  EnigUenig,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import { useUttalelse } from "../../../../../hooks";

const EnigUenigSvar = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
}: {
  id: number;
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
}) => {
  const { data: uttalelse } = useUttalelse();
  const { fields = {} } =
    useForm<Record<SpørsmålFormId, SpørsmålUttalelseViewModel["enigUenig"]>>();

  const field = fields[getSpørsmålFormId(id, "enigUenig")] ?? {
    value: uttalelse?.spørsmål.find((s) => s.spørsmålId === id)?.enigUenig,
  };

  if (!forhandsvisning && readonly) {
    let svar: React.ReactNode = field.value;
    if (svar === EnigUenig.IkkeAngitt) return null;
    if (svar === EnigUenig.HverkenEller)
      svar = <FormattedMessage id="formLabels.hverkenEller" />;
    if (svar === EnigUenig.HeltEnig)
      svar = <FormattedMessage id="formLabels.heltEnig" />;
    if (svar === EnigUenig.DelvisEnig)
      svar = <FormattedMessage id="formLabels.delvisEnig" />;
    if (svar === EnigUenig.DelvisUenig)
      svar = <FormattedMessage id="formLabels.delvisUenig" />;
    if (svar === EnigUenig.HeltUenig)
      svar = <FormattedMessage id="formLabels.heltUenig" />;
    return <div className="Sporsmal-body Sporsmal-body--bold">{svar}</div>;
  }
  const buttonClass = cls({
    "Sporsmal-button": true,
    "Sporsmal-button--error": field.error,
  });
  const button = (val: EnigUenig, txt: React.ReactNode) => {
    const active = field.value === val;
    return (
      <Button
        className={buttonClass}
        active={active}
        disabled={readonly}
        onClick={() => field.onChange(active ? EnigUenig.IkkeAngitt : val)}
      >
        {txt}
      </Button>
    );
  };

  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={obligatorisk}>
        <Tooltip
          message={field.message}
          position="top"
          align="left"
          disabled={!field.error}
          alwaysShow
        >
          <ButtonGroup className="Sporsmal-buttons">
            {button(
              EnigUenig.HeltEnig,
              <FormattedMessage id="formLabels.heltEnig" />,
            )}
            {button(
              EnigUenig.DelvisEnig,
              <FormattedMessage id="formLabels.delvisEnig" />,
            )}
            {button(
              EnigUenig.HverkenEller,
              <FormattedMessage id="formLabels.hverkenEller" />,
            )}
            {button(
              EnigUenig.DelvisUenig,
              <FormattedMessage id="formLabels.delvisUenig" />,
            )}
            {button(
              EnigUenig.HeltUenig,
              <FormattedMessage id="formLabels.heltUenig" />,
            )}
          </ButtonGroup>
        </Tooltip>
      </Obligatorisk>
    </div>
  );
};

export default EnigUenigSvar;
