import { cls } from "@utdanningsdirektoratet/utils/cls";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { Icon } from "@utdanningsdirektoratet/icon";
import { FormattedMessage } from "react-intl";
import NotatLink from "./NotatLink";
import { HøringsNotatViewModel } from "../../../../ApiClients";
import { TIMESTAMP_FORMAT } from "../../../../constants/dateFormats";
import { useUttalelse } from "../../../../hooks";
import { useContextState } from "../../../../StateProvider";

const getNotatLink = (
  currentNotat: HøringsNotatViewModel,
  notat: HøringsNotatViewModel,
  ingenMerkander: boolean = false,
) => {
  const selected = currentNotat.id === notat.id;
  const itemClass = cls({
    "Sidebar-item": true,
    "Sidebar-item--selected": selected,
  });
  return (
    <div className={itemClass} key={`sidebar-item-${notat.id}`}>
      <NotatLink notat={notat} ingenMerkander={ingenMerkander} />
      <Icon className="Sidebar-item-icon" type="small" icon="angle right" />
    </div>
  );
};

const Sidebar = ({
  currentNotat,
  notater,
  innsendt = false,
  showTimestamp,
}: {
  currentNotat: HøringsNotatViewModel;
  notater: Array<HøringsNotatViewModel>;
  innsendt?: boolean;
  showTimestamp: boolean;
}) => {
  const { data: uttalelse } = useUttalelse();
  const [{ autosaving }] = useContextState();

  const body = notater
    .filter(
      (n) =>
        !innsendt ||
        uttalelse?.notater.find((hnu) => hnu.notatId === n.id)?.hasFeedback ||
        n.rekkefølge === 0,
    )
    .sort((a, b) => a.rekkefølge - b.rekkefølge)
    .map((notat) => getNotatLink(currentNotat, notat));

  if (body.length <= 1) return null;

  const notaterWithoutFeedback = notater
    .filter(
      (n) =>
        innsendt &&
        !uttalelse?.notater.find((hnu) => hnu.notatId === n.id)?.hasFeedback &&
        n.rekkefølge !== 0,
    )
    .map((notat) => getNotatLink(currentNotat, notat, true));

  const timestampClass = cls({
    "Sidebar-timestamp": true,
    "Sidebar-timestamp--visible":
      showTimestamp && autosaving !== undefined && !!uttalelse?.endret,
  });

  return (
    <nav className="Sidebar">
      <div className="Sidebar-wrapper">{body}</div>

      {innsendt && notaterWithoutFeedback.length > 0 ? (
        <div className="Sidebar-wrapper Sidebar-wrapper--faded">
          {notaterWithoutFeedback}
        </div>
      ) : null}
      {autosaving !== undefined || !!uttalelse?.endret ? (
        <div className={timestampClass}>
          <FormattedMessage id="uttalelse.sistLagret" />:{" "}
          {autosaving !== true && !!uttalelse?.endret ? (
            formatDate(uttalelse?.endret, TIMESTAMP_FORMAT)
          ) : (
            <FormattedMessage id="uttalelse.lagrerUtkastSpinner" />
          )}
        </div>
      ) : null}
    </nav>
  );
};

export default Sidebar;
