import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { close, Popin } from "@utdanningsdirektoratet/popin";
import {
  useForm,
  useFormValidation,
  validationFunctions,
  Values,
} from "@utdanningsdirektoratet/form";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { useNavigate } from "react-router";
import { hide, show } from "@utdanningsdirektoratet/slowaction";
import SendInnForm from "./components/SendInnForm";
import {
  BekreftetStatus,
  GeografiObligatoriskhet,
  HoeringClient,
  MalgruppeViewModel,
  Obligatoriskhet,
  Sektor,
} from "../../../../ApiClients";
import {
  useUttalelse,
  useHoering,
  useInnsendingsKode,
  useLocale,
} from "../../../../hooks";
import { epostValidation } from "../../../../logic/validation";
import mapVm from "../../../../vm/mapVm";
import { removeUtkast } from "../../../../utils/localStorageUtils";
import { getLocaleCode } from "../../../../utils/localeUtils";
import { useContextState } from "../../../../StateProvider";
import { routes } from "../../../../routes";
import { mapFormToUttalelse } from "../../../../vm/vmUtils";

export type FieldType = {
  navn: string;
  epost: string;
  sektor: Sektor;
  stilling: string;
  malgruppe: number | string;
  fylke: string;
  kommune: string;
  representerer: string;
  undertype: Record<string, boolean>;
  bekreftRepresentasjon: boolean;
};

const client = new HoeringClient();

const SendInnPopin = ({ id, ...rest }: { id: string }) => {
  const intl = useIntl();
  const { data: hoering } = useHoering();
  const { data: uttalelse, mutate } = useUttalelse();
  const userMalform = useLocale();
  const innsedingsKode = useInnsendingsKode();
  const navigate = useNavigate();
  const [{ ingenMerknader }, dispatch] = useContextState();
  const { values: uttalelseValues } = useForm();

  const [representererEnkeltperson, setRepresenterer] = useState<boolean>();

  const bekreftRepresentasjonRequired = (valgtMalgruppe: MalgruppeViewModel) =>
    valgtMalgruppe.bekreftRepresentasjon;

  const fylkeRequired = (valgtMalgruppe: MalgruppeViewModel) =>
    valgtMalgruppe.geografiObligatoriskhet ===
      GeografiObligatoriskhet.FylkeOgKommuneObligatorisk ||
    valgtMalgruppe.geografiObligatoriskhet ===
      GeografiObligatoriskhet.FylkeObligatorisk;

  const kommuneRequired = (valgtMalgruppe: MalgruppeViewModel) =>
    valgtMalgruppe.geografiObligatoriskhet ===
    GeografiObligatoriskhet.FylkeOgKommuneObligatorisk;

  const representererRequired = (valgtMalgruppe: MalgruppeViewModel) =>
    valgtMalgruppe.innsenderRepresentererObligatoriskhet ===
    Obligatoriskhet.Obligatorisk;

  const stillingRequired = (valgtMalgruppe: MalgruppeViewModel) =>
    valgtMalgruppe.innsenderStillingObligatoriskhet ===
    Obligatoriskhet.Obligatorisk;

  const malgruppeHasValue = (values: Values<FieldType>) => {
    const { malgruppe } = values;
    return malgruppe && malgruppe !== "" && malgruppe !== "null";
  };

  const { målgrupper } = hoering;
  const valgtMalgruppe = (values: Values<FieldType>) => {
    const { malgruppe } = values;
    return målgrupper.find((m) => m.id === +malgruppe)!;
  };
  const sektorer = [...new Set(målgrupper.map((m) => m.sektor))];
  const formValidation = useFormValidation<FieldType>({
    navn: {
      value: "",
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({ id: "validation.navnErObligatorisk" }),
        },
      ],
    },
    epost: {
      value: "",
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({ id: "validation.epostErObligatorisk" }),
        },
        epostValidation(intl),
      ],
    },
    sektor: {
      value: sektorer.length === 1 ? sektorer[0] : undefined,
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({
            id: "validation.sektorErObligatorisk",
          }),
        },
      ],
    },
    malgruppe: {
      value: målgrupper.length === 1 ? målgrupper[0].id : undefined,
      validation: [
        {
          ...validationFunctions.numberRequired,
          message: intl.formatMessage({
            id: "validation.malgruppeErObligatorisk",
          }),
        },
      ],
    },
    representerer: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !representererRequired(valgtMalgruppe(values)) ||
            validationFunctions.required.validation(input, values),
          message: (_, values) =>
            intl.formatMessage(
              { id: "validation.representererErObligatorisk" },
              { 0: `${valgtMalgruppe(values).innsenderRepresentererEtikett}` },
            ),
        },
      ],
    },
    fylke: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !fylkeRequired(valgtMalgruppe(values)) ||
            validationFunctions.numberRequired.validation(input, values),
          message: intl.formatMessage({ id: "validation.fylkeErObligatorisk" }),
        },
      ],
    },
    kommune: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !kommuneRequired(valgtMalgruppe(values)) ||
            validationFunctions.numberRequired.validation(input, values),
          message: intl.formatMessage({
            id: "validation.kommuneErObligatorisk",
          }),
        },
      ],
    },
    stilling: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !stillingRequired(valgtMalgruppe(values)) ||
            validationFunctions.required.validation(input, values),
          message: intl.formatMessage({
            id: "validation.navnPaStillingErObligatorisk",
          }),
        },
      ],
    },
    undertype: {
      value: {},
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !valgtMalgruppe(values).undertypeErObligatorisk ||
            Object.keys(input).filter((i) => input[i]).length > 0,
          message: intl.formatMessage({
            id: "validation.oppgiMinstEttAlternativ",
          }),
        },
      ],
    },
    bekreftRepresentasjon: {
      value: false,
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !bekreftRepresentasjonRequired(valgtMalgruppe(values)) ||
            validationFunctions.boolRequired.validation(input, values),
          message: (_, values) =>
            valgtMalgruppe(values).bekreftRepresentasjonFeilMelding[
              getLocaleCode(userMalform)
            ]
              ? (valgtMalgruppe(values).bekreftRepresentasjonFeilMelding[
                  getLocaleCode(userMalform)
                ] as string)
              : intl.formatMessage(
                  { id: "sendInnPopin.bekreftRepresentasjonFeilMelding" },
                  { 0: valgtMalgruppe(values).tittel.toLowerCase() },
                ),
        },
      ],
    },
  });

  if (!uttalelse) return null;

  const sendInnForm = async () => {
    show();
    try {
      dispatch({
        type: "SET_AUTOSAVING",
        payload: false,
      });

      const vm = mapVm(
        uttalelse.eksternId,
        mapFormToUttalelse(uttalelse, uttalelseValues),
        ingenMerknader,
        userMalform,
        false,
        formValidation.values,
      );

      const data = await client.submitUttalelse(
        hoering.id,
        innsedingsKode,
        uttalelse.eksternId,
        vm,
      );
      await mutate();
      close(id);
      removeUtkast(data.hoeringId, data.eksternId);
      navigate(routes.uttalelse(data.hoeringId, data.eksternId));

      if (data.bekreftetStatus !== BekreftetStatus.IkkeBekreftet) {
        newNotification(
          "success",
          intl.formatMessage({ id: "uttalelse.uttalelseBekreftet" }),
          intl.formatMessage({ id: "uttalelse.uttalelseVilBliBehandlet" }),
        );
      }
    } catch (error) {
      dispatch({
        type: "SET_AUTOSAVING",
        payload: undefined,
      });
    }
    hide();
  };

  const popinTitle = ingenMerknader ? (
    <FormattedMessage id="sendInnPopin.viHarIngenMerknaderTilHoeringen" />
  ) : (
    <FormattedMessage id="sendInnPopin.title" />
  );

  return (
    <Popin
      className="SendInnPopin"
      id={id}
      title={popinTitle}
      cancelButton={false}
    >
      <SendInnForm
        popinId={id}
        {...rest}
        {...formValidation}
        representererEnkeltperson={representererEnkeltperson}
        setRepresenterer={setRepresenterer}
        onSuccess={sendInnForm}
      />
    </Popin>
  );
};

export default SendInnPopin;
