import { RichTextView, ConfigBuilder } from "@utdanningsdirektoratet/richtext";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Element } from "react-scroll";
import { isEmpty } from "@utdanningsdirektoratet/utils/string";
import SporsmalUttalelse from "./SporsmalUttalelse";
import { getTextWithLocale } from "../../../../utils/localeUtils";
import { SeksjonViewModel } from "../../../../ApiClients";
import { useLocale } from "../../../../hooks";
import { scrollToId, ScrollType } from "../../../../utils/scrollUtils";

const SeksjonUttalelse = ({
  seksjons,
  skjulNummerering,
  readonly,
  forhandsvisning = false,
  editManueltRegistert,
  ingenMerknader,
  autosave,
}: {
  seksjons: Array<SeksjonViewModel>;
  skjulNummerering: boolean;
  readonly: boolean;
  ingenMerknader: boolean;
  forhandsvisning?: boolean;
  editManueltRegistert: boolean;
  autosave: (immediate?: boolean) => Promise<string>;
}) => {
  const locale = useLocale();
  const body = seksjons
    .sort((a, b) => {
      return a.rekkefølge - b.rekkefølge;
    })
    .map((seksjon) => {
      const { tittel, brødTekst, nummerering, id, nivå } = seksjon;

      const malformTittel = getTextWithLocale(tittel, locale);
      const malformBrodtekst = getTextWithLocale(brødTekst, locale);

      const pageClass = cls({
        "Page-section": true,
        "Page-section--empty": isEmpty(malformBrodtekst),
      });

      const titleClass = cls({
        "u-h1": nivå === 0,
        "u-h2": nivå === 1,
        "u-h3": nivå > 1,
        "Seksjon-title": true,
      });

      const TitleTag =
        `h${Math.min(nivå + 2, 6)}` as keyof JSX.IntrinsicElements;

      const sporsmalElements = seksjon.spørsmål
        .sort((a, b) => a.rekkefølge - b.rekkefølge)
        .map((sporsmal) => {
          return (
            <SporsmalUttalelse
              key={`SporsmalUttalelse-${sporsmal.id}`}
              sporsmal={sporsmal}
              editManueltRegistert={editManueltRegistert}
              forhandsvisning={forhandsvisning}
              ingenMerknader={ingenMerknader}
              readonly={readonly}
              autosave={autosave}
            />
          );
        });

      const config = new ConfigBuilder()
        .withImages()
        .withAllowedTags(["span", "sup"])
        .build();
      const html = malformBrodtekst
        .replace(/<table/gi, '<span class="table-wrapper"><table')
        .replace(/<\/table>/gi, "</table></span>");

      return (
        <div key={`seksjonsuttalelse-${id}`} className="Seksjon">
          <Element name={scrollToId(ScrollType.SEKSJON, id)} />
          <div className={pageClass}>
            <div id={nummerering.replace(/\.$/gi, "")}>
              <TitleTag id={`seksjon-${id}`} className={titleClass}>
                {skjulNummerering
                  ? malformTittel
                  : `${nummerering} ${malformTittel}`}
              </TitleTag>
            </div>
            <div className="u--marginBottom2">
              <RichTextView html={html} config={config} />
            </div>
          </div>
          <div>{sporsmalElements}</div>
        </div>
      );
    });

  return <div>{body}</div>;
};

export default SeksjonUttalelse;
