import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { Element } from "react-scroll";
import { FormValidation } from "@utdanningsdirektoratet/form";
import type { FieldType } from "../SendInnPopin";
import { MalgruppeViewModel } from "../../../../../ApiClients";

const Undertype = ({
  fields,
  valgtMalgruppe,
}: {
  fields: FormValidation<FieldType>["fields"];
  valgtMalgruppe: MalgruppeViewModel;
}) => {
  const onChangeWrapped = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    const newValue = valgtMalgruppe.kanHaFlereUndertyper
      ? { ...fields.undertype.value, [value]: checked }
      : { [value]: checked };
    fields.undertype.onChange(newValue);
  };

  const undertyper = valgtMalgruppe.målgruppeUnderTyper
    .filter((undertype) => undertype.erAktiv)
    .sort((a, b) => a.rekkefølge - b.rekkefølge)
    .map((undertype) => {
      if (valgtMalgruppe.kanHaFlereUndertyper) {
        return (
          <Checkbox
            name="undertyper"
            key={`undertype-${undertype.id}`}
            className="Grid-12-12 Grid-md-6-12 SendInnPopin-checkbox"
            label={undertype.tittel}
            checked={fields.undertype.value[undertype.id]}
            onChange={onChangeWrapped}
            value={undertype.id}
            error={fields.undertype.error}
          />
        );
      }
      return (
        <Radio
          name="undertyper"
          key={`undertype-${undertype.id}`}
          className="Grid-12-12 Grid-md-6-12 SendInnPopin-checkbox"
          label={undertype.tittel}
          onChange={onChangeWrapped}
          checked={fields.undertype.value[undertype.id]}
          value={undertype.id}
          error={fields.undertype.error}
        />
      );
    });

  if (undertyper.length === 0) return null;

  return (
    <div className="Grid-12-12 u--marginTop1">
      <Element name="undertype" />
      <Tooltip
        className="u--block"
        message={fields.undertype.message}
        align="right"
        position="top"
        disabled={!fields.undertype.error}
        alwaysShow
      >
        {undertyper}
      </Tooltip>
    </div>
  );
};

export default Undertype;
