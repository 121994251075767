import { useEffect, useState } from "react";
import { Icon } from "@utdanningsdirektoratet/icon";
import { FormattedMessage } from "react-intl";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { show, usePopin } from "@utdanningsdirektoratet/popin";
import { Button } from "@utdanningsdirektoratet/button";
import { ButtonLink } from "@utdanningsdirektoratet/link";
import emailClosed from "../../../../static/images/email-closed.svg";
import emailOpen from "../../../../static/images/email-open.svg";
import emailText from "../../../../static/images/email-text.svg";
import hand from "../../../../static/images/hand.svg";
import RedigerEpostPopin from "./RedigerEpostPopin";
import { DATE_FORMAT } from "../../../constants/dateFormats";
import { BekreftetStatus } from "../../../ApiClients";
import { useUttalelse } from "../../../hooks";

const TutorialOverlay = () => {
  const [redigerEpostPopinId] = usePopin();
  const { data: uttalelse } = useUttalelse();
  const [overlayIsOpen, setOverlayIsOpen] = useState(
    uttalelse?.innsendt &&
      uttalelse?.bekreftetStatus === BekreftetStatus.IkkeBekreftet,
  );

  useEffect(() => {
    setOverlayIsOpen(
      uttalelse?.innsendt &&
        uttalelse?.bekreftetStatus === BekreftetStatus.IkkeBekreftet,
    );
  }, [uttalelse?.bekreftetStatus, uttalelse?.innsendt]);

  const onConfirmLaterClick = (e: React.MouseEvent) => {
    e.preventDefault();

    setOverlayIsOpen(false);
  };

  const onEditEmailClick = (e: React.MouseEvent) => {
    e.preventDefault();
    show(redigerEpostPopinId);
  };

  if (!overlayIsOpen || !uttalelse) return null;

  const { eksternId, innsenderEpost, mottatt } = uttalelse;

  return (
    <div className="TutorialOverlay">
      <div className="TutorialOverlay-wrapper">
        <div className="TutorialOverlay-box">
          <img src={emailClosed} alt="E-post" />
          <div className="TutorialOverlay-badge">1</div>
          <div>
            <FormattedMessage id="innsendtUttalelse.bekreftelsesEpostSendtTil" />
          </div>
          <div data-hj-suppress>
            <strong>{innsenderEpost}</strong>
          </div>
          {mottatt && <div>{formatDate(mottatt, DATE_FORMAT)}</div>}
        </div>
        <div className="TutorialOverlay-box TutorialOverlay-box--icon">
          <Icon
            className="TutorialOverlay-arrow"
            icon="arrow right"
            type="large"
          />
        </div>
        <div className="TutorialOverlay-box">
          <img src={emailOpen} alt="E-post" />
          <div>
            <FormattedMessage id="innsendtUttalelse.vennligstApneEpostFra" />{" "}
            <FormattedMessage id="innsendtUttalelse.innsendtTilInstitusjon" />.
          </div>
          <p className="u--marginTop1 u--marginBottom1">
            <FormattedMessage id="innsendtUttalelse.ikkeMottattEpost" />
          </p>
          <Button
            className="TutorialOverlay-button"
            onClick={(e) => onEditEmailClick(e)}
          >
            <FormattedMessage id="innsendtUttalelse.sendEpostPåNytt" />
          </Button>
        </div>
        <div className="TutorialOverlay-box TutorialOverlay-box--icon">
          <Icon
            className="TutorialOverlay-arrow"
            icon="arrow right"
            type="large"
          />
        </div>
        <div className="TutorialOverlay-box">
          <img src={emailText} alt="" />
          <img src={hand} className="TutorialOverlay-hand" alt="" />
          <div>
            <FormattedMessage id="innsendtUttalelse.klikkPaLenken" />
          </div>
        </div>
      </div>
      <div className="TutorialOverlay-footer">
        <br />
        <br />
        <ButtonLink onClick={(e) => onConfirmLaterClick(e)}>
          <FormattedMessage id="innsendtUttalelse.bekreftSenere" />
        </ButtonLink>
      </div>
      <RedigerEpostPopin
        id={redigerEpostPopinId}
        eksternId={eksternId!}
        epost={innsenderEpost!}
      />
    </div>
  );
};

export default TutorialOverlay;
