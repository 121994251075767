import { Link, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getTextWithLocale } from "../../../../utils/localeUtils";
import { HøringsNotatViewModel } from "../../../../ApiClients";
import { useLocale } from "../../../../hooks";

const NotatLink = ({
  notat,
  ingenMerkander,
}: {
  notat: HøringsNotatViewModel;
  ingenMerkander: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const locale = useLocale();
  searchParams.set("notatId", `${notat.id}`);
  return (
    <Link className="Sidebar-item-link" to={`?${searchParams.toString()}`}>
      {getTextWithLocale(notat.menyTittel, locale)}
      {ingenMerkander ? (
        <div className="Sidebar-item-label">
          <FormattedMessage id="innsendtUttalelse.ingenMerknader" />
        </div>
      ) : null}
    </Link>
  );
};

export default NotatLink;
