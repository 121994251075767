import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { FormattedMessage, useIntl } from "react-intl";
import { PubliseringsStatus } from "../../../../ApiClients";
import {
  useForhåndsvisning,
  useHoering,
  useUttalelse,
} from "../../../../hooks";

const MessageBanner = ({
  editManueltRegistert,
}: {
  editManueltRegistert?: boolean | undefined;
}) => {
  const intl = useIntl();
  const { data: hoering } = useHoering();
  const { data: uttalelse } = useUttalelse();
  const { publiseringsStatus } = hoering;
  const forhandsvisning = useForhåndsvisning();

  const { innsendt = false, manueltRegistrert = false } = uttalelse ?? {};

  const utgatt = hoering.isExpired;
  const lukketMenKanSendesInn =
    hoering.isClosedOrExpired && hoering.lukketInnsending;

  let message;
  if (editManueltRegistert) {
    message = (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: "uttalelse.andreKanalerRedigering",
          }),
        }}
      />
    );
  } else if (manueltRegistrert) {
    message = <FormattedMessage id="uttalelse.manueltRegistrert" />;
  } else if (lukketMenKanSendesInn) {
    message = <FormattedMessage id="uttalelse.lukketMenKanSendesInn" />;
  } else if (
    !innsendt &&
    publiseringsStatus === PubliseringsStatus.IkkePublisert &&
    !forhandsvisning
  ) {
    message = <FormattedMessage id="uttalelse.denneHoeringenErIkkePublisert" />;
  } else if (
    (!innsendt && publiseringsStatus === PubliseringsStatus.PublisertLukket) ||
    forhandsvisning
  ) {
    message = <FormattedMessage id="uttalelse.ikkeApenForUttalelse" />;
  } else if (
    !innsendt &&
    publiseringsStatus === PubliseringsStatus.PublisertÅpen &&
    utgatt
  ) {
    message = <FormattedMessage id="uttalelse.fristUtlopt" />;
  }

  if (!message) return null;

  return (
    <InpageMessage
      type="warning"
      message={message}
      className="u--marginBottom1"
    />
  );
};

export default MessageBanner;
