import { FormValidation } from "@utdanningsdirektoratet/form";
import Kommune from "./Kommune";
import Fylke from "./Fylke";
import type { FieldType } from "../SendInnPopin";
import {
  GeografiObligatoriskhet,
  MalgruppeViewModel,
} from "../../../../../ApiClients";

const FylkeKommune = ({
  fields,
  resetFields,
  valgtMalgruppe,
}: {
  valgtMalgruppe: MalgruppeViewModel;
  fields: FormValidation<FieldType>["fields"];
  resetFields: FormValidation<FieldType>["resetFields"];
}) => {
  const visFylke =
    valgtMalgruppe.geografiObligatoriskhet ===
      GeografiObligatoriskhet.FylkeObligatorisk ||
    valgtMalgruppe.geografiObligatoriskhet ===
      GeografiObligatoriskhet.FylkeOgKommuneObligatorisk;

  if (!visFylke) return null;

  return (
    <div className="Grid-12-12 SendInnPopin-wrapper">
      <Fylke fields={fields} resetFields={resetFields} />
      <Kommune fields={fields} valgtMalgruppe={valgtMalgruppe} />
    </div>
  );
};

export default FylkeKommune;
