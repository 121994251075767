import {
  isProblemDetail,
  isValidationProblemDetail,
} from "@utdanningsdirektoratet/utils/object";
import type { ProblemDetails, ValidationProblemDetails } from "../ApiClients";

export function isValidationProblemDetailsObject(
  error: unknown,
): error is ValidationProblemDetails {
  return (
    (<ValidationProblemDetails>error).type?.includes(
      "https://tools.ietf.org/html/rfc7231",
    ) || isValidationProblemDetail(error)
  );
}

const validationMessage = "Innsendingen hadde en eller fler feil";

export function getValidationTitle(problem: ValidationProblemDetails) {
  return problem.title ?? validationMessage;
}

export function getValidationMessage(problem: ValidationProblemDetails) {
  return Object.values(problem.errors ?? {})
    .flatMap((e) => e)
    .join(", ");
}

export function isProblemDetails(error: unknown): error is ProblemDetails {
  return (
    (error != null &&
      typeof (<ProblemDetails>error).type === "string" &&
      typeof (<ProblemDetails>error).title === "string") ||
    isProblemDetail(error)
  );
}
