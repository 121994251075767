import useSWR, { SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDeferredValue } from "react";
import {
  FylkeClient,
  HoeringClient,
  UttalelseClient,
  VersionClient,
} from "./ApiClients";
import {
  FORHANDSVISNINGSKODE,
  INNSENDINGSKODE,
  MANUELLREGISTRERINGSKODE,
  NOTAT_ID,
} from "./constants/queryParam";
import { emptyGuid, useContextState } from "./StateProvider";
import { routes } from "./routes";
import mapVm from "./vm/mapVm";
import { mapFormToUttalelse } from "./vm/vmUtils";

const hoeringClient = new HoeringClient();
const uttalelseClient = new UttalelseClient();
const fylkeClient = new FylkeClient();
const versionClient = new VersionClient();

const SWRConfig = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
} satisfies SWRConfiguration;

export const useFylker = () => {
  return useSWR("fylker", () => fylkeClient.getFylker(), SWRConfig);
};

export const useLocale = () => {
  const [{ locale }] = useContextState();
  return locale;
};

export const useEksternId = () => {
  const { eksternId } = useParams<{
    eksternId: string;
  }>();
  return eksternId ?? emptyGuid;
};

export const useVersion = () => {
  return useSWR("version", () => versionClient.getVersion(), {
    ...SWRConfig,
    refreshInterval: 600000,
  });
};

export const useLeseTilgangsKode = () => {
  const { leseTilgangsKode } = useParams<{
    leseTilgangsKode: string;
  }>();
  return leseTilgangsKode;
};

export const useManuellRegistreringsKode = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(MANUELLREGISTRERINGSKODE) ?? undefined;
};

export const useInnsendingsKode = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(INNSENDINGSKODE) ?? undefined;
};

export const useForhåndsvisning = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(FORHANDSVISNINGSKODE) ?? undefined;
};

export const useNotatId = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get(NOTAT_ID);
};

export const useHoeringId = () => {
  const { hoeringId } = useParams<{
    hoeringId: string;
  }>();
  return hoeringId!;
};

export const useHoering = () => {
  const hoeringId = useHoeringId();
  const forhandsVisningsKode = useForhåndsvisning();
  const innsendingsKode = useInnsendingsKode();
  return useSWR(
    routes.hoering(hoeringId),
    () => hoeringClient.get(+hoeringId!, forhandsVisningsKode, innsendingsKode),
    { ...SWRConfig, suspense: true },
  );
};

export const useUttalelse = () => {
  const leseTilgangsKode = useLeseTilgangsKode();
  const hoeringId = useHoeringId();
  const uttalelseId = useEksternId();
  const forhandsVisningsKode = useForhåndsvisning();

  const deferredUttalelseId = useDeferredValue(uttalelseId);

  return useSWR(
    routes.uttalelse(hoeringId, leseTilgangsKode ?? uttalelseId),
    async () =>
      uttalelseClient.getUttalelse(
        +hoeringId,
        leseTilgangsKode ?? uttalelseId!,
        !!leseTilgangsKode,
        forhandsVisningsKode,
      ),
    {
      ...SWRConfig,
      suspense: uttalelseId === deferredUttalelseId,
    },
  );
};

export const useAutoSave = () => {
  const hoeringId = useHoeringId();
  const eksternId = useEksternId();
  const { data: uttalelse } = useUttalelse();
  const [{ ingenMerknader }] = useContextState();
  const locale = useLocale();

  return useSWRMutation(
    `/autosave/${hoeringId}/${eksternId}`,
    (_, { arg }: { arg: Record<string, unknown> }) => {
      const model = mapVm(
        eksternId,
        mapFormToUttalelse(uttalelse!, arg),
        ingenMerknader,
        locale,
        false,
      );
      return hoeringClient.autoSave(+hoeringId, eksternId, model);
    },
  );
};
