import { FormattedMessage } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { useContextState } from "../StateProvider";
import { useUttalelse } from "../hooks";

const AutoSaveLoader = () => {
  const { data: uttalelse } = useUttalelse();
  const [{ autosaving }] = useContextState();

  if (uttalelse?.innsendt) return null;

  let content = <span>&nbsp;</span>;
  if (autosaving === true) {
    content = (
      <span>
        <FormattedMessage id="uttalelse.lagrerUtkastSpinner" />
        <div className="AutoSaveLoader-spinner">
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-1" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-2" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-3" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-4" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-5" />
        </div>
      </span>
    );
  } else if (autosaving !== undefined && !!uttalelse?.endret) {
    content = (
      <span>
        <FormattedMessage id="uttalelse.utkastLagretSpinner" />
        <Icon color="white" type="small" placement="after" icon="check" />
      </span>
    );
  }

  const autoSaveLoaderClass = cls({
    AutoSaveLoader: true,
    "AutoSaveLoader--visible": autosaving !== undefined,
  });

  return <div className={autoSaveLoaderClass}>{content}</div>;
};

export default AutoSaveLoader;
