import { Input } from "@utdanningsdirektoratet/input";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import { FormValidation } from "@utdanningsdirektoratet/form";
import type { FieldType } from "../SendInnPopin";
import { MalgruppeViewModel, Obligatoriskhet } from "../../../../../ApiClients";

const MinStilling = ({
  fields,
  valgtMalgruppe,
}: {
  fields: FormValidation<FieldType>["fields"];
  valgtMalgruppe: MalgruppeViewModel;
}) => {
  const intl = useIntl();
  const visMinStilling =
    valgtMalgruppe.innsenderStillingObligatoriskhet ===
      Obligatoriskhet.Obligatorisk ||
    valgtMalgruppe.innsenderStillingObligatoriskhet ===
      Obligatoriskhet.Valgfritt;

  if (!visMinStilling) return null;

  const stillingLabel =
    valgtMalgruppe.innsenderStillingObligatoriskhet !==
    Obligatoriskhet.Obligatorisk
      ? `${intl.formatMessage({ id: "sendInnPopin.valgfritt" })}`
      : undefined;

  return (
    <div className="Grid-12-12">
      <Element name="stilling" />
      <label className="SendInnPopin-label" htmlFor="minStilling">
        {intl.formatMessage({ id: "sendInnPopin.minStilling" })}
      </label>
      <Input
        {...fields.stilling}
        id="minStilling"
        className="SendInnPopin-input"
        placeholder={stillingLabel}
        aria-label={intl.formatMessage({ id: "sendInnPopin.minStilling" })}
      />
    </div>
  );
};

export default MinStilling;
