import { Outlet, useLocation } from "react-router";
import { SlowAction } from "@utdanningsdirektoratet/slowaction";
import { PopinManager } from "@utdanningsdirektoratet/popin";
import { usePageViewTracking } from "@utdanningsdirektoratet/hooks/usePageViewTracking";
import { Notifications } from "@utdanningsdirektoratet/notifications";
import { IntlProvider } from "react-intl";

import { Progress } from "@utdanningsdirektoratet/progress";
import { getLocaleCode } from "./utils/localeUtils";
import { flatten } from "./utils/objectUtils";
import { texts } from "./logic/initialState";
import { useLocale } from "./hooks";

const App = () => {
  const locale = useLocale();
  const location = useLocation();
  usePageViewTracking();

  return (
    <IntlProvider locale="no" messages={flatten(texts[getLocaleCode(locale)])}>
      <div lang={`${getLocaleCode(locale)}-NO`}>
        <Outlet />
        {!location.pathname.includes("pdf") && (
          <>
            <Progress />
            <PopinManager />
            <SlowAction />
            <Notifications closeOnClick />
          </>
        )}
      </div>
    </IntlProvider>
  );
};

export default App;
