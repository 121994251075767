import { remove, update } from "@utdanningsdirektoratet/utils/array";

export const localstorageAvailable = () => {
  try {
    const storage = window.localStorage || global.localStorage;
    const x = "__storage_test__";
    storage.setItem(x, x);
    if (storage.getItem(x) !== x) {
      return false;
    }
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

type UtkastType = {
  eksternId?: string;
  endret: Date | null | undefined;
};

export const getUtkasts = (hoeringId: string | number): Array<UtkastType> => {
  if (localstorageAvailable()) {
    return JSON.parse(localStorage.getItem(`${hoeringId}`)!) || [];
  }
  return [];
};

export const addOrUpdateUtkast = (
  hoeringId: string | number,
  utkast: UtkastType,
) => {
  if (!utkast || !utkast.eksternId) return;
  if (localstorageAvailable()) {
    const utkasts = getUtkasts(hoeringId);
    const index = utkasts.findIndex((u) => u.eksternId === utkast.eksternId);
    if (index !== -1) {
      localStorage.setItem(
        `${hoeringId}`,
        JSON.stringify(update(utkasts, index, utkast)),
      );
    } else {
      localStorage.setItem(
        `${hoeringId}`,
        JSON.stringify([...utkasts, utkast]),
      );
    }
  }
};

export const removeUtkast = (
  hoeringId: string | number,
  eksternId?: string,
) => {
  if (localstorageAvailable()) {
    const utkasts = getUtkasts(hoeringId);
    const index = utkasts.findIndex((u) => u.eksternId === eksternId);
    if (index !== -1) {
      localStorage.setItem(
        `${hoeringId}`,
        JSON.stringify(remove(utkasts, index)),
      );
    }
  }
};
