import { FormattedMessage } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Button, ButtonGroup } from "@utdanningsdirektoratet/button";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { useForm } from "@utdanningsdirektoratet/form";
import Obligatorisk from "./Obligatorisk";
import {
  HøringsNotatUttalelseViewModel,
  PositivNegativHoldning,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import {
  getNotatFormId,
  getSpørsmålFormId,
  NotatFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import { useUttalelse } from "../../../../../hooks";

const PositivNegativSvar = ({
  readonly,
  obligatorisk,
  forhandsvisning,
  id,
  generell,
}: {
  id: number;
  readonly: boolean;
  obligatorisk?: boolean;
  forhandsvisning: boolean;
  generell?: boolean;
}) => {
  const { data: uttalelse } = useUttalelse();
  const { fields = {} } =
    useForm<
      Record<
        NotatFormId | SpørsmålFormId,
        | SpørsmålUttalelseViewModel["positivNegativHoldning"]
        | HøringsNotatUttalelseViewModel["generellKommentar"]
      >
    >();

  let positiv = "formLabels.positiv";
  let negativ = "formLabels.negativ";
  if (generell) {
    positiv = "formLabels.genereltPositiv";
    negativ = "formLabels.genereltNegativ";
  }

  const field = fields[
    generell
      ? getNotatFormId(id, "generellHoldning")
      : getSpørsmålFormId(id, "positivNegativHoldning")
  ] ?? {
    value: generell
      ? uttalelse?.notater.find((n) => n.notatId === id)?.generellHoldning
      : uttalelse?.spørsmål.find((s) => s.spørsmålId === id)
          ?.positivNegativHoldning,
  };

  if (!forhandsvisning && readonly) {
    let svar: React.ReactNode = field.value;
    if (svar === PositivNegativHoldning.IkkeAngitt) return null;
    if (svar === PositivNegativHoldning.HverkenEller)
      svar = <FormattedMessage id="formLabels.hverkenEller" />;
    if (svar === PositivNegativHoldning.Positiv) {
      svar = (
        <span>
          <Icon icon="plus" type="small" placement="before" />
          <FormattedMessage id={positiv} />
        </span>
      );
    }
    if (svar === PositivNegativHoldning.Negativ) {
      svar = (
        <span>
          <Icon icon="minus" type="small" placement="before" />
          <FormattedMessage id={negativ} />
        </span>
      );
    }
    return <div className="Sporsmal-body Sporsmal-body--bold">{svar}</div>;
  }
  const buttonClass = cls({
    "Sporsmal-button": true,
    "Sporsmal-button--error": field.error,
  });
  const button = (val: PositivNegativHoldning, txt: React.ReactNode) => {
    const active = field.value === val;
    return (
      <Button
        className={buttonClass}
        active={active}
        disabled={readonly}
        onClick={() => {
          field.onChange(active ? PositivNegativHoldning.IkkeAngitt : val);
        }}
      >
        {txt}
      </Button>
    );
  };

  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={obligatorisk}>
        <Tooltip
          message={field.message}
          position="top"
          align="left"
          disabled={!field.error}
          alwaysShow
        >
          <ButtonGroup className="Sporsmal-buttons">
            {button(
              PositivNegativHoldning.Positiv,
              <FormattedMessage id={positiv} />,
            )}
            {button(
              PositivNegativHoldning.Negativ,
              <FormattedMessage id={negativ} />,
            )}
            {button(
              PositivNegativHoldning.HverkenEller,
              <FormattedMessage id="formLabels.hverkenEller" />,
            )}
          </ButtonGroup>
        </Tooltip>
      </Obligatorisk>
    </div>
  );
};

export default PositivNegativSvar;
