export const routes = {
  hoering: (hoeringId: string | number) => `/hoering/${hoeringId}`,
  uttalelse: (hoeringId: string | number, uttalelseId: string) =>
    `${routes.hoering(hoeringId)}/uttalelse/${uttalelseId}`,
  utkast: (hoeringId: string | number, uttalelseId: string) =>
    `${routes.hoering(hoeringId)}/utkast/${uttalelseId}`,
  lesutkast: (hoeringId: string | number, leseTilgangsKode: string) =>
    `${routes.hoering(hoeringId)}/lesutkast/${leseTilgangsKode}`,
  pdfHoering: (hoeringId: string | number) => `/pdf/${hoeringId}`,
  pdfUttalelse: (hoeringId: string | number, uttalelseId: string) =>
    `/pdf/${hoeringId}/uttalelse/${uttalelseId}`,
  pdfUtkast: (hoeringId: string | number, uttalelseId: string) =>
    `/pdf/${hoeringId}/utkast/${uttalelseId}`,
};
